import { twMerge } from "tailwind-merge";
import { IconComponent } from "./Icon";

const TickIcon: IconComponent = ({ className }) => (
  <svg
    className={twMerge("stroke-white", className)}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="current"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  >
    <path d="M2.75 8.75l3.5 3.5 7-7.5" />
  </svg>
);

export default TickIcon;
