import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import ClubForm from "components/Forms/ClubForm";
import { injectModal } from "lib/injectParams";

const EditClub: React.FC<{ clubId: string }> = ({ clubId }) => {
  return (
    <AppModal title="Edit club" className="md:max-w-[350px]">
      <ClubForm attributes={{}} id={clubId} />
    </AppModal>
  );
};

export default injectModal(ApplicationView(EditClub));
