import { ModalAlert } from "@mixitone/components";
import { ApplicationView, useController } from "@mixitone/mvc";
import { FormController } from "./FormController";


const DeleteModal: React.FC<{ name: string }> = ({ name }) => {
  const controller = useController(FormController);
  const { saving } = controller.state;

  if (!controller.state.showDeleteModal) {
    return null;
  }

  return <ModalAlert
    title="Delete event"
    message={`Are you sure you want to delete this ${name}?`}
    onConfirm={() => controller.actionConfirmDelete()}
    onCancel={() => controller.actionCancelDelete()}
    confirmText="Yes"
    cancelText="No"
    loading={saving}
  />;
};

export default ApplicationView(DeleteModal);
