import { Button, Input, LabelledField, Spinner, Toggle, bindControllerState } from "@mixitone/components";
import DollarIcon from "@mixitone/components/icons/dollar.svg";
import RacketIcon from "@mixitone/components/icons/racket.svg";
import TimerIcon from "@mixitone/components/icons/timer.svg";

import { Account, Night, RecurringSession } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import { isNil } from "@mixitone/util";
import Form from "./Form";
import { FormController } from "./FormController";

class SessionFormController extends FormController<
  Night,
  { club_id: string; recurring_session_id?: string },
  { recurringSession: RecurringSession }
> {
  override findModel(id: string) {
    return Night.query().find(id);
  }

  override buildRecord() {
    return new Night({
      courts: 5,
      player_fee: 0,
      game_set_time: 15,
      warm_up_time: 10,
      club_id: this.state.attributes.club_id,
      session_id: this.state.attributes.recurring_session_id,
      auto_fill: true,
    });
  }

  override async onLoad() {
    if (this.state.attributes.recurring_session_id) {
      this.state.recurringSession = await RecurringSession.query().find(
        this.state.attributes.recurring_session_id,
      );
    } else if (this.state.newRecord) {
      const recurringSessions = await RecurringSession.query()
        .eq("club_id", this.state.attributes.club_id)
        .all();
      this.state.recurringSession =
        recurringSessions.find((session) => session.weekday === new Date().getDay()) || recurringSessions[0];
    }

    if (this.state.newRecord) {
      if (this.state.recurringSession) {
        this.state.record.setAttributes(this.state.recurringSession.nightAttributes);
      }
    }
  }

  override async saveRecord(record: Night): Promise<void> {
    record.club_id = this.state.attributes.club_id;
    record.session_id = this.state.attributes.recurring_session_id;
    record.account_id = Account.current?.id;

    if (this.state.newRecord) {
      record.date = new Date();
    }

    await record.save();

    if (this.state.newRecord) {
      await record.ensureSets();

      if (isNil(record.token)) {
        record.token = record.id;
        await record.save();
      }
    }
  }
}

const SessionForm: React.FC = () => {
  const controller = SessionFormController.use();
  const { bind, bindChecked } = bindControllerState(controller, "record");
  const { saving, loading } = controller.state;

  if (loading) return <Spinner size={64} />;

  return (
    <Form name="sessoin">
      <fieldset disabled={saving}>
        <LabelledField
          label="Number of courts"
          htmlFor="courts"
          icon={<RacketIcon className="fill-blue-600" />}
        >
          <Input type="number" {...bind("courts")} min={1} />
        </LabelledField>

        <LabelledField
          label="Player fee"
          htmlFor="player_fee"
          icon={<DollarIcon className="fill-green-600" />}
        >
          <Input type="number" prefix="$" {...bind("player_fee")} max={999.99} min={0.0} step={1.0} />
        </LabelledField>

        <LabelledField
          label="Warm up time"
          htmlFor="warm_up_time"
          helpText="Warm up timer will start counting down when the first player checks in"
          icon={<TimerIcon className="stroke-red-800" />}
        >
          <Input type="number" suffix="minutes" {...bind("warm_up_time")} max={60} min={0} step={1} />
        </LabelledField>

        <LabelledField
          label="Game time"
          htmlFor="game_set_time"
          icon={<TimerIcon className="stroke-green-800" />}
        >
          <Input type="number" suffix="minutes" {...bind("game_set_time")} max={60} min={1} step={1} />
        </LabelledField>

        <LabelledField
          label="Auto fill"
          htmlFor="auto_fill"
          helpText="Automatically fill courts with players"
          inline
        >
          <Toggle {...bindChecked("auto_fill")} />
        </LabelledField>
      </fieldset>

      <div className="flex items-center justify-between w-full gap-2">
        <Button type="submit" disabled={saving} spinner={saving}>
          Start session
        </Button>
      </div>
    </Form>
  );
};

export default SessionFormController.scope(ApplicationView(SessionForm));
