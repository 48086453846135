import {
  Button,
  Input,
  LabelledField,
  ModalAlert,
  Select,
  Spinner,
  TextArea,
  bindControllerState
} from "@mixitone/components";
import Account from "@mixitone/models/Account";
import AccountUser from "@mixitone/models/AccountUser";
import Club, { ClubPreferences } from "@mixitone/models/Club";
import { ApplicationView } from "@mixitone/mvc";
import { List } from "@mixitone/oom";
import { FormController } from "components/Forms/FormController";
import Form from "./Form";

class ClubFormController extends FormController<
  Club,
  {},
  {
    contacts: List<AccountUser>;
    preferences: ClubPreferences;
  }
> {
  override buildRecord(): Club {
    return new Club({ account_id: Account.current?.id! });
  }
  override findModel(id: string) {
    return Club.query().find(id);
  }

  override async onLoad() {
    const club = this.state.record;
    const contacts = await AccountUser.query().eq("account_id", club.account_id).orderBy("name").all();

    if (this.state.newRecord) {
      club.contact_id = AccountUser.current?.uniqueId;
    }

    this.setState({
      contacts: new List(...contacts),
      preferences: club.preferences || {},
    });
  }

  override async saveRecord(club: Club) {
    club.preferences = this.state.preferences;
    return club.save();
  }

  override afterDestroy(): void {
    this.actionForward("/account");
  }
}

const ClubForm: React.FC = () => {
  const controller = ClubFormController.use();
  const { loading, saving, contacts } = controller.state;
  const { canDelete } = controller;
  const { bind } = bindControllerState(controller, "record");

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner size={64} />
      </div>
    );
  }

  return (
    <>
      <Form name="club">
        <fieldset disabled={saving}>
          <LabelledField label="Name" htmlFor="name">
            <Input required minLength={1} type="text" {...bind("name")} autoFocus />
          </LabelledField>
          <LabelledField label="Address" htmlFor="address">
            <TextArea {...bind("address")} rows={4} />
          </LabelledField>
          <LabelledField label="Contact" htmlFor="contact">
            <Select {...bind("contact_id")}>
              {contacts.map((contact) => (
                <option key={`contact-${contact.id}`} value={contact.id as string}>
                  {contact.name}
                </option>
              ))}
            </Select>
          </LabelledField>
        </fieldset>

        <div className="flex items-center justify-end gap-2">
          {canDelete && (
            <Button type="button" kind="danger" disabled={saving} onClick={() => controller.actionDelete()}>
              Archive
            </Button>
          )}
          <Button type="submit" disabled={saving} spinner={saving}>
            Save
          </Button>
        </div>
      </Form>
      {controller.state.showDeleteModal && (
        <ModalAlert
          title="Archive club"
          message="Are you sure you want to archive this club?"
          onConfirm={() => controller.actionConfirmDelete()}
          onCancel={() => controller.actionCancelDelete()}
          confirmText="Yes"
          cancelText="No"
          loading={saving}
        />
      )}
    </>
  );
};

export default ClubFormController.scope(ApplicationView(ClubForm));
