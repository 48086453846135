import { Input, Spinner, TextArea, bindControllerState } from "@mixitone/components";
import AccountUser from "@mixitone/models/AccountUser";
import IncidentLog from "@mixitone/models/IncidentLog";
import { ApplicationView } from "@mixitone/mvc";
import Form from "./Form";
import { FormController } from "./FormController";
import FormField from "./FormField";

import dayjs from "dayjs";
import FormButtons from "./FormButtons";

class DateTime {
  private dayjs: dayjs.Dayjs;

  constructor(date: Date) {
    this.dayjs = dayjs(date);
  }

  get date() {
    return this.dayjs.format("YYYY-MM-DD");
  }
  set date(value: string) {
    this.dayjs = dayjs(`${value}T${this.time}`);
  }

  get time() {
    return this.dayjs.format("HH:mm");
  }
  set time(value: string) {
    this.dayjs = dayjs(`${this.date}T${value}`);
  }

  get value() {
    return this.dayjs.toDate();
  }
}

class IncidentLogFormController extends FormController<
  IncidentLog,
  { night_id: string },
  {
    incident_occurred_at: DateTime;
  }
> {
  override findModel(id: string) {
    return IncidentLog.query().find(id);
  }
  override buildRecord(): IncidentLog {
    this.state.incident_occurred_at = new DateTime(new Date());

    const log = new IncidentLog({
      night_id: this.state.attributes.night_id,
      logged_by_account_user_id: AccountUser.current?.id!,
      account_id: AccountUser.current?.account_id!,
      incident_description: "",
    });
    log.incident_occurred_at = new Date();

    return log;
  }

  override async onLoad(): Promise<void> {
    this.state.incident_occurred_at = new DateTime(this.state.record.incident_occurred_at || new Date());
  }

  override saveRecord(record: IncidentLog): Promise<void> {
    record.night_id = this.state.attributes.night_id;
    record.incident_occurred_at = this.state.incident_occurred_at.value;
    return record.save();
  }
}

const IncidentLogForm: React.FC = () => {
  const controller = IncidentLogFormController.use();
  const { bind } = bindControllerState(controller, "record");
  const { bind: bindDateTime } = bindControllerState(controller, "incident_occurred_at");
  const { saving, loading } = controller.state;

  return loading ? (
    <Spinner size={64} />
  ) : (
    <Form name="Incident log">
      <fieldset disabled={saving}>
        <FormField label="Incident description" htmlFor="incident_description">
          <TextArea
            required
            autoFocus
            {...bind("incident_description")}
            placeholder="Please describe the incident"
          />
        </FormField>
        <FormField label="Date of incident" htmlFor="incident_occurred_at_date">
          <Input type="date" required {...bindDateTime("date")} id="incident_occurred_at_date" />
        </FormField>
        <FormField label="Approximate time of incident" htmlFor="incident_occurred_at_time">
          <Input type="time" required {...bindDateTime("time")} id="incident_occurred_at_time" />
        </FormField>
      </fieldset>
      <FormButtons />
    </Form>
  );
};

export default IncidentLogFormController.scope(ApplicationView(IncidentLogForm));
