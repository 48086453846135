import { omit } from "@mixitone/util";
import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const Select: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [value, setValue] = useState(props.value);
  const rest = omit(props, ["value", "onChange", "children"]);
  const { children } = props;

  useEffect(() => {
    if (hasChanged) return;
    setValue(props.value);
  }, [props.value, hasChanged]);

  useEffect(() => {
    if (!hasChanged) return;

    props.onChange({
      target: { value },
    } as React.ChangeEvent<HTMLSelectElement>);
  }, [hasChanged, value]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setHasChanged(true);
    setValue(e.target.value);
  };

  return (
    <select
      value={value}
      onChange={handleChange}
      {...rest}
      className={twMerge("mt-1 block w-full rounded border border-gray-300 p-2", rest.className)}
    >
      {children}
    </select>
  );
};
