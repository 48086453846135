import { Club, FillRule } from "@mixitone/models";
import { List, subscribeList } from "@mixitone/oom";
import { withStateToggle } from "@mixitone/util";
import MixitoneController from "controllers/MixitoneController";

interface State {
  loading: boolean;
  club: Club;
  fillRules: List<FillRule>;
  deleteRule: FillRule | null;
}
interface Props {
  club: Club;
}

export class FillRuleListController extends MixitoneController<State, Props> {
  get initialState(): State {
    return {
      loading: true,
      club: new Club({}),
      fillRules: new List(),
      deleteRule: null,
    };
  }

  @withStateToggle("loading")
  async initialize(props: Props) {
    const { club } = props;
    this.state.club = club;
    await Promise.all([club.fill_rules.reload(), club.groups.load(), club.club_players.load()]);
    this.state.fillRules = new List(...club.fill_rules);
    this.addDependency(await subscribeList(this.state.fillRules, club.fill_rules.query));
  }

  actionDeleteRule(rule: FillRule) {
    this.state.deleteRule = rule;
  }

  async actionConfirmDeleteRule() {
    if (this.state.deleteRule) {
      await this.state.deleteRule.destroy();
    }
    this.state.deleteRule = null;
  }

  async actionCancelDeleteRule() {
    this.state.deleteRule = null;
  }
}
