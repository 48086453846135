import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GroupDot } from "@mixitone/components";
import { Input } from "@mixitone/components/Controls/Form";
import TrashIcon from "@mixitone/components/icons/trash.svg";
import Group from "@mixitone/models/Group";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import { useCallback, useState } from "react";
import GroupListController from "./GroupListController";

interface Props {
  group: Group;
  groupRef(el: HTMLElement | null): void;
  onGroupClick(playerId: string): void;
}

const GroupRow: React.FC<Props> = ({ group, groupRef, onGroupClick }) => {
  const controller = GroupListController.use();
  const { club } = controller.state;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: group.uniqueId });
  const [saving, setSaving] = useState(false);

  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    group.name = e.target.value;
  };

  const handleBlur = () => {
    setSaving(true);
    controller.actionUpdateGroup(group).then(() => {
      setSaving(false);
    });
  };

  const handleGroupDelete = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    controller.actionDeleteGroup(group.id!);
  }, []);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style} className="border-b border-slate-200" {...attributes}>
      <td className="w-[30px] cursor-grab py-2 pr-2" {...listeners}>
        <GroupDot group={group} size={24} />
      </td>
      <td>
        <Input
          unstyled
          className={clsx("w-full", {
            saving,
          })}
          type="text"
          onChange={handleGroupNameChange}
          onBlur={handleBlur}
          value={group.name || ""}
        />
      </td>
      <td className="text-right">
        <div className="flex items-center justify-center">
          {club.hasManagePermission() && (
            <button onClick={handleGroupDelete}>
              <TrashIcon className="h-[19px] w-[19px]" />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ApplicationView(GroupRow);
