import {
  Autocomplete,
  Button,
  Input,
  LabelledField,
  Spinner,
  Toggle,
  bindControllerState,
} from "@mixitone/components";

import { Club, ClubPlayer, ClubPlayerToken, Night, Player } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import { List } from "@mixitone/oom";
import { formatDate, isPresent } from "@mixitone/util";
import RatingTooltip from "components/common/RatingTooltip";
import { GroupSelect } from "components/GroupSelect";
import SearchIcon from "components/icons/SearchIcon";
import { useId } from "react";
import Form from "./Form";
import { FormController } from "./FormController";

interface Props {
  search?: boolean;
  autoFocus?: boolean;
  canDelete?: boolean;
  canCancel?: boolean;
}

class ClubPlayerFormController extends FormController<
  ClubPlayer,
  { club_id?: string; name?: string; email?: string; phone?: string; token?: string },
  {
    sessionCount: number;
    lastSession: string | null | Date;
    club: Club;
    clubPlayers: List<ClubPlayer>;
    search: boolean;
    searchTerm: string;
    token?: string;
    tokens?: ClubPlayerToken[];
  },
  Props
> {
  override findModel(id: string): Promise<ClubPlayer> {
    return ClubPlayer.query().preload("group").find(id);
  }
  override buildRecord(): ClubPlayer {
    return new ClubPlayer({
      active: true,
      club_id: this.state.attributes.club_id,
      name: this.state.attributes.name,
      email: this.state.attributes.email,
      phone: this.state.attributes.phone,
    });
  }
  override saveRecord(record: ClubPlayer): Promise<void> {
    record.club_id = this.state.attributes.club_id;
    return record.save();
  }
  override async onLoad() {
    this.state.token = this.state.attributes.token;

    this.state.club = await Club.query().find(this.state.attributes.club_id!);
    await this.state.club.groups.reload();

    if (this.props.search) {
      await this.state.club.club_players.load();
      this.state.clubPlayers = new List(...this.state.club.club_players);
    }

    if (this.state.newRecord) return;

    (["email", "phone"] as const).forEach((key) => {
      if (isPresent(this.state.attributes[key])) {
        this.state.record.writeAttribute(key, this.state.attributes[key]);
      }
    });

    const tokens = await ClubPlayerToken.query()
      .eq("club_player_id", this.state.record.id)
      .preload("token")
      .all();
    const sessionCount = await Player.query().eq("club_player_id", this.state.record.id).count();
    const lastSessions = await Night.query()
      .joins(Player.query().eq("club_player_id", this.state.record.uniqueId))
      .orderBy("date", "desc")
      .limit(1)
      .pluck("date");

    const lastSession = lastSessions?.[0];
    this.setState({ sessionCount, lastSession, tokens });
  }

  get group() {
    return (
      this.state.club.groups.find((group) => group.id === this.state.record.group_id) ||
      this.state.club.groups[0]
    );
  }

  override get canDelete(): boolean {
    if (this.props.search) return false;
    if (this.props.canDelete === false) return false;
    return this.state.club.hasManagePermission();
  }

  async actionSearchSelect(player: ClubPlayer) {
    if (!player || !player.persisted) {
      return;
    }

    const { record } = this.state;
    (["email", "phone"] as const).forEach((key) => {
      if (record.dirtyAttributes.has(key) && isPresent(record.readAttribute(key))) {
        player.writeAttribute(key, record.readAttribute(key));
      }
    });

    this.state.record = player;
    this.state.newRecord = false;
  }
}

const ClubPlayerForm: React.FC = () => {
  const controller = ClubPlayerFormController.use();
  const { saving, loading, newRecord, sessionCount, lastSession, club, clubPlayers, errors, token, tokens } =
    controller.state;
  const { search, autoFocus, canCancel } = controller.props;
  const id = useId();

  if (loading) return <Spinner size={64} />;

  const { canDelete, group } = controller;
  const { bind, bindChecked } = bindControllerState(controller, "record");
  const showSearch = search && newRecord;
  const disabled = saving || !club.hasManagePermission();

  return (
    <Form name="player">
      <fieldset disabled={disabled}>
        <LabelledField label="Name" htmlFor="name">
          {showSearch && (
            <Autocomplete
              className="w-full flex-grow bg-transparent py-2 pl-7 text-sm focus:outline-none"
              {...bind("name")}
              suggestions={clubPlayers}
              onSelect={(player: ClubPlayer) => {
                controller.actionSearchSelect(player);
              }}
              blankSuggestion={new ClubPlayer({ name: "Register new player" })}
              renderSuggestion={(player) => player.name!}
              prefix={<SearchIcon className="w-[14px] stroke-gray-600" />}
              data-1p-ignore
              autoFocus={autoFocus}
              error={errors?.name}
            />
          )}
          {!showSearch && (
            <Input
              autoFocus={autoFocus}
              required
              minLength={1}
              type="text"
              {...bind("name")}
              data-1p-ignore
            />
          )}
        </LabelledField>
        {token && (
          <LabelledField label="NFC token" htmlFor={`${id}-token`}>
            <Input
              id={`${id}-token`}
              type="text"
              value={token}
              className="text-ellipsis"
              disabled
              onChange={() => {}}
            />
          </LabelledField>
        )}
        {tokens && (
          <LabelledField label="NFC token" htmlFor={`${id}-token`}>
            <Input
              type="text"
              id={`${id}-token`}
              value={tokens.map((t) => t.token?.token_value || "").join(", ")}
              disabled
              onChange={() => {}}
            />
          </LabelledField>
        )}
        {!newRecord && (
          <LabelledField label="Active" htmlFor="active">
            <Toggle value="active" {...bindChecked("active")} />
          </LabelledField>
        )}
        <LabelledField label="Group" htmlFor="group_id">
          <div>
            <GroupSelect
              clubGroups={club.groups}
              value={group}
              onSelectGroup={(group) => (controller.state.record.group = group)}
            />
          </div>
        </LabelledField>
        <LabelledField label="Email" optional htmlFor="email">
          <Input type="email" {...bind("email")} />
        </LabelledField>

        <LabelledField label="Phone" optional htmlFor="phone">
          <Input type="tel" {...bind("phone")} />
        </LabelledField>
      </fieldset>

      {!search && !newRecord && (
        <div className="mb-4">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">Sessions:</h3>
            <p>{sessionCount}</p>
          </div>
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">Last session:</h3>
            <p>{formatDate(lastSession)}</p>
          </div>
          <div className="flex items-center justify-between">
            <h3 className="flex items-center gap-1 text-sm font-medium">
              <span>Rating:</span>
              <RatingTooltip />
            </h3>
            <p>{controller.state.record.ratingOrDefault}</p>
          </div>
        </div>
      )}

      <div className="flex items-center justify-end gap-2">
        {canDelete && (
          <Button type="button" kind="danger" disabled={saving} onClick={() => controller.actionDelete()}>
            Delete
          </Button>
        )}
        <Button type="submit" disabled={disabled} spinner={saving}>
          {showSearch && <>Create</>}
          {!showSearch && <>Save</>}
        </Button>
        {canCancel && (
          <Button type="button" kind="plain" onClick={() => controller.actionCancel()}>
            Cancel
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ClubPlayerFormController.scope(ApplicationView(ClubPlayerForm));
