import { Model, ModelAttributes, collection, model, modelConfig, reference } from "@mixitone/oom";
import { isNil, makeRecordSetRelationshipsBidirectional } from "@mixitone/util";
import Color from "color";
import AccountUser from "./AccountUser";
import ClubPlayer from "./ClubPlayer";
import FillRule, { RuleType } from "./FillRule";
import Group, { defaultColors } from "./Group";
import Night from "./Night";
import { PermissionKey } from "./ClubPermission";

export interface ClubPreferences {
  enableQrScanning?: boolean;
}

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    address: {
      type: "string",
    },
    contact_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    is_temporary: {
      type: "boolean",
    },
    name: {
      type: "string",
    },
    preferences: {
      type: "json",
      jsonType: {} as ClubPreferences,
    },
    updated_at: {
      type: "datetime",
    },
  },
  {
    club_players: collection<ClubPlayer>(() => ClubPlayer, "club_id"),
    groups: collection<Group>(() => Group, "club_id", { order: "index" }),
    fill_rules: collection<FillRule>(() => FillRule, "club_id", { order: "created_at" }),
  },
  {
    contact: reference(() => AccountUser, "contact_id"),
  },
);

interface Club extends ModelAttributes<typeof config> {}

@model("clubs", config)
class Club extends Model<typeof config> {
  override canEdit() {
    return AccountUser.current?.role === "Admin" || AccountUser.current?.id === this.contact_id;
  }

  async ensureGroups() {
    await this.groups.load();

    if (this.groups.length === 0) {
      const bGroup = new Group({
        account_id: this.account_id,
        club_id: this.id,
        name: "Beginner",
        index: 0,
      });
      const iGroup = new Group({
        account_id: this.account_id,
        club_id: this.id,
        name: "Intermediate",
        index: 1,
      });
      const aGroup = new Group({
        account_id: this.account_id,
        club_id: this.id,
        name: "Advanced",
        index: 2,
      });

      try {
        await Promise.all([bGroup.save(), iGroup.save(), aGroup.save()]);
        this.groups.push(bGroup, iGroup, aGroup);
      } catch (e) {
        await this.groups.reload();
      }
    } else {
      let changed = false;
      if (this.groups.some((g) => isNil(g.color))) {
        changed = true;
        this.groups.forEach((g, i) => {
          g.color = Color(defaultColors[i % defaultColors.length]).rgbNumber();
        });
      }
      if (this.groups.some((g) => isNil(g.initial))) {
        changed = true;
        this.groups.forEach((g, i) => {
          if (g.name) g.initial = g.name[0].toUpperCase();
        });
      }
      if (changed) await this.groups.save();
    }

    if (this.groups.length === 0) {
      throw new Error("Failed to create groups");
    }
  }

  async startSession() {
    const night = await Night.createAndSetup({
      account_id: this.account_id,
      club_id: this.id,
    });
    if (!night) throw new Error("Failed to create night");
    return night.token!;
  }

  override async create() {
    const result = await super.create();
    await this.ensureGroups();
    return result;
  }

  /**
   * Returns a map of group IDs to an array of group IDs that the group excludes
   */
  get groupExclusions(): Record<string, string[]> {
    const groupExclusions: Record<string, Set<string>> = {};
    const rules = this.fill_rules;

    for (const rule of rules) {
      if (rule.subjectType !== "Group") continue;
      if (!rule.group_id) continue;
      if (!rule.rule_type) continue;

      const ruleType = rule.rule_type as RuleType;

      switch (ruleType) {
        case "never": {
          if (!rule.group_ids) continue;

          if (!groupExclusions[rule.group_id]) {
            groupExclusions[rule.group_id] = new Set();
          }

          for (const groupId of rule.group_ids) {
            groupExclusions[rule.group_id].add(groupId);
          }
          break;
        }
        case "prefers":
        case "only": {
          if (!rule.group_ids) continue;

          if (!groupExclusions[rule.group_id]) {
            groupExclusions[rule.group_id] = new Set();
          }

          for (const groupId of this.groups.map((g) => g.id!)) {
            if (!rule.group_ids.includes(groupId)) {
              groupExclusions[rule.group_id].add(groupId);
            }
          }
          break;
        }
      }
    }

    makeRecordSetRelationshipsBidirectional(groupExclusions);
    return Object.fromEntries(Object.entries(groupExclusions).map(([k, v]) => [k, Array.from(v)]));
  }

  hasPermission(permission: PermissionKey) {
    const clubPermission = AccountUser.current?.clubPermissionFor(this.id!);
    const hasPermission = clubPermission?.[permission] ?? false;
    return hasPermission;
  }
  hasAdminPermission() {
    return this.hasPermission("admin_club");
  }
  hasManagePermission() {
    return this.hasPermission("manage_club");
  }
  hasSessionPermission() {
    return this.hasPermission("manage_sessions");
  }
  hasViewPermission() {
    return this.hasPermission("view_club");
  }
}
export default Club;
