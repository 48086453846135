import { Button, EmptyState, ListSkeleton, ModalAlert, PanelController } from "@mixitone/components";
import InfoIcon from "@mixitone/components/icons/info.svg";
import PlusIcon from "@mixitone/components/icons/plus.svg";
import RulesIcon from "@mixitone/components/icons/rules.svg";
import { ApplicationView } from "@mixitone/mvc";
import { useEffect, useMemo } from "react";
import FillRuleDescription from "./FillRuleDescription";
import { FillRuleListController } from "./FillRuleListController";
import FillRuleRow from "./FillRuleRow";

const FillRulesList = () => {
  const controller = FillRuleListController.use();
  const { loading, club, fillRules, deleteRule } = controller.state;
  const panelController = PanelController.useOptional();

  const addButton = useMemo(
    () =>
      club.hasManagePermission() && (
        <Button
          className="whitespace-nowrap text-xs font-normal"
          onClick={(e) => {
            e.preventDefault();
            controller.actionOpenModal("/clubs/:clubId/groups/rules/new", {
              params: {
                clubId: club.id!,
              },
            });
          }}
        >
          <PlusIcon className="mr-1 w-[12px]" />
          Add rule
        </Button>
      ),
    [club],
  );

  useEffect(() => {
    if (panelController) {
      panelController.actionSetButtons(addButton);
      return () => {
        panelController.actionSetButtons(null);
      };
    }
  }, [club]);

  if (loading) {
    return <ListSkeleton itemCount={3} />;
  }

  return (
    <>
      {!panelController && <div className="mb-4 flex w-full justify-end">{addButton}</div>}
      {fillRules.length === 0 && (
        <EmptyState Icon={RulesIcon}>
          <p>No rules have been added yet</p>
        </EmptyState>
      )}
      {fillRules.length > 0 && (
        <>
          <table className="w-full">
            <colgroup>
              <col />
              <col />
            </colgroup>
            <thead>
              <tr className="text-xs uppercase text-gray-500">
                <th className="text-left">Rule</th>
                <th className="text-left" colSpan={2}>
                  Description
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fillRules.map((rule, idx) => (
                <FillRuleRow key={rule.domKey()} rule={rule} index={idx} />
              ))}
            </tbody>
          </table>
          <div className="mt-10 flex items-center gap-4 rounded-lg border border-sky-200 bg-sky-50 p-4 text-sm shadow shadow-sky-900/20">
            <InfoIcon className="h-[30px] w-[30px] stroke-brand-700" />
            <div>
              <p>Rules are applied when filling games automatically</p>
            </div>
          </div>
        </>
      )}
      {deleteRule && (
        <ModalAlert
          title="Delete rule"
          message={
            <span>
              Are you sure you want to delete the rule: <FillRuleDescription rule={deleteRule} />
            </span>
          }
          onConfirm={() => controller.actionConfirmDeleteRule()}
          onCancel={() => controller.actionCancelDeleteRule()}
          confirmText="Yes, delete"
          cancelText="No"
        />
      )}
    </>
  );
};

export default FillRuleListController.scope(ApplicationView(FillRulesList));
