import { useCallback, useMemo } from "react";
import {
  generatePath,
  NavigateOptions as NavOptions,
  useLocation,
  useNavigate as useRouterNavigate,
  useParams as useRouterParams,
} from "react-router-dom";
import { NavigateOptions, To } from "./types";
import { useModalContext } from "./context";

export const hooks = <
  Path extends string,
  Params extends Record<string, any>,
  ModalPath extends string,
  ModalParams extends Record<string, any>,
>() => {
  return {
    useParams: <P extends keyof Params>(path: P) => useRouterParams<Params[typeof path]>() as Params[P],
    useModalParams: () => {
      return useModalContext() as ModalPath extends keyof ModalParams ? ModalParams[ModalPath] : never;
    },
    useNavigate: () => {
      const navigate = useRouterNavigate();

      return useCallback(
        <P extends Path | To<Path> | number>(to: P, options?: NavigateOptions<P, Params>) => {
          if (typeof to === "number") return navigate(to);
          const path = generatePath(
            typeof to === "string" ? to : to.pathname,
            options?.params || ({} as any),
          );
          return navigate(
            typeof to === "string" ? path : { pathname: path, search: to.search, hash: to.hash },
            options,
          );
        },
        [navigate],
      );
    },
    useModals: () => {
      const location = useLocation();
      const navigate = useRouterNavigate();

      type BaseOptions<P> = NavOptions &
        (P extends keyof Params ? { at?: P; routeParams?: Params[P] } : { at?: P; routeParams?: never });

      type ModalState = Array<{
        path: ModalPath;
        params: ModalPath extends keyof ModalParams ? ModalParams[ModalPath] : undefined;
      }>;

      type OpenOptions<P, M extends ModalPath> = BaseOptions<P> & {
        state?: Record<string, any>;
      } & (M extends keyof ModalParams ? { params: ModalParams[M] } : { params?: undefined });

      return useMemo(() => {
        return {
          current: (location.state?.modals || []) as ModalState,
          open: <P extends Path, M extends ModalPath>(path: M, options: OpenOptions<P, M>) => {
            const { at, routeParams, params, state = {}, ...opts } = options || {};
            const pathname = routeParams ? generatePath(at || "", routeParams) : at;
            const modals = (location.state?.modals || []) as ModalState;
            modals.push({ path, params: params as any });
            navigate(pathname || location.pathname, {
              ...opts,
              state: { ...location.state, ...state, modals },
            });
          },
          close: <P extends Path>(options?: BaseOptions<P> & { state?: Record<string, any> }) => {
            const { at, routeParams, state = {}, ...opts } = options || { state: {} };
            const pathname = routeParams ? generatePath(at || "", routeParams) : at;
            const modals = (location.state?.modals || []) as ModalState;
            modals.pop();
            navigate(pathname || location.pathname, {
              ...opts,
              state: { ...location.state, ...state, modals },
            });
          },
        };
      }, [location, navigate]);
    },
  };
};
