import { GroupDot } from "@mixitone/components";
import ClubPlayer from "@mixitone/models/ClubPlayer";
import Player from "@mixitone/models/Player";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

interface Props {
  player: Player | ClubPlayer;
  hideName?: boolean;
  inline?: boolean;
  size?: number;
  className?: string;
  dotClassName?: string;
  whitespaceWrap?: boolean;
  onClick?: React.MouseEventHandler;
}

const PlayerDot: React.FC<Props> = ({
  player,
  inline,
  size,
  className,
  dotClassName,
  hideName,
  onClick,
  whitespaceWrap,
}) => {
  const dotSize = size || 17;
  const name = player instanceof ClubPlayer ? player.name : player.clubPlayer.name;

  const handleClick: React.MouseEventHandler = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      className={twMerge(
        clsx("player-dot flex cursor-pointer items-center gap-2", {
          "flex-col": !inline,
        }),
        className,
      )}
      onClick={handleClick}
    >
      <GroupDot group={player.group} size={dotSize} className={dotClassName} />
      <span
        data-testid="player-name"
        className={clsx({
          "whitespace-nowrap": !whitespaceWrap,
          invisible: hideName,
        })}
      >
        {name}
      </span>
    </div>
  );
};

export default ApplicationView(PlayerDot);
