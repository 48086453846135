import { observable } from "@nx-js/observer-util";
import { Model, Query } from "./";

type SortableRecord = {
  id: string | undefined;
};

/**
 * A list, a subclass of array, for records. It is observable and has additional
 * methods for mutation, sorting and finding records by id
 */
export class List<Item extends SortableRecord> extends Array<Item> {
  private _comparator: Parameters<Array<Item>["sort"]>[0] | null = null;
  private _loading: boolean;
  private _query: Query<Item & Model<any>, any> | null = null;

  // Return a new Array rather than a list when using filter, map etc
  static get [Symbol.species]() {
    return Array;
  }

  constructor(...items: Item[]);
  constructor(query: Query<Item & Model<any>, any>);
  constructor(...itemsOrQuery: unknown[]) {
    super();
    this._comparator = null;
    this._loading = false;

    if (itemsOrQuery.length === 1 && itemsOrQuery[0] instanceof Query) {
      this._loading = true;
      this._query = itemsOrQuery[0] as Query<Item & Model<any>, any>;
      const self = observable(this);
      this._query.all().then((items) => {
        self.push(...items);
        self.resort();
        self._loading = false;
      });
      return self;
    } else {
      this.push(...(itemsOrQuery as Item[]));
    }
    return observable(this);
  }

  clone() {
    const list = new List<Item>();
    list._comparator = this._comparator;
    list._loading = this._loading;
    list._query = this._query;
    list.setItems(this);
    return list;
  }

  get loading() {
    return this._loading;
  }

  get query() {
    return this._query;
  }

  get ids() {
    return new Set(this.map((item) => item.id));
  }

  hasId(id: string) {
    return this.ids.has(id);
  }

  findIndexById(id: string) {
    return this.findIndex((item) => item?.id === id);
  }

  findById(id: string) {
    return this.find((item) => item.id === id);
  }

  orderBy(column: keyof Item, direction: "asc" | "desc" = "asc") {
    const comparator = (a: Item, b: Item) => {
      const aValue = a[column];
      const bValue = b[column];
      if (aValue === bValue) return 0;
      return aValue > bValue ? 1 : -1;
    };

    return this.sortBy(comparator, direction);
  }

  sortBy(comparator: Parameters<Array<Item>["sort"]>[0], direction: "asc" | "desc" = "asc") {
    if (comparator && direction === "desc") {
      this._comparator = (a: Item, b: Item) => -comparator(a, b);
    } else {
      this._comparator = comparator;
    }
    this.resort();
  }

  insert(item: Item) {
    if (item.id && this.hasId(item.id)) {
      return this.replace(item);
    }

    this.push(item);
    this.resort();
  }

  replace(item: Item) {
    if (!item.id) throw new Error("item must have an id");

    const index = this.findIndexById(item.id);
    if (index === -1) return;
    this[index] = item;
    this.resort();
  }

  remove(item: Item) {
    if (!item.id) throw new Error("item must have an id");

    return this.removeById(item.id);
  }

  removeById(id: string) {
    const index = this.findIndexById(id);
    if (index === -1) return;
    this.splice(index, 1);
  }

  removeDuplicates() {
    const ids = new Set();
    const indexes: number[] = [];

    for (let index = 0; index < this.length; index++) {
      const id = this[index].id;
      if (ids.has(id)) {
        indexes.push(index);
      } else {
        ids.add(id);
      }
    }

    for (let i = indexes.length - 1; i >= 0; i--) {
      this.splice(indexes[i], 1);
    }
  }

  swap(fromIndex: number, toIndex: number) {
    const [item] = this.splice(fromIndex, 1);
    this.splice(toIndex, 0, item);
  }

  override splice(start: number, deleteCount?: number | undefined): Item[];
  override splice(start: number, deleteCount: number, ...items: Item[]): Item[];
  override splice(start: unknown, deleteCount?: unknown, ...rest: unknown[]): Item[] {
    const result = super.splice(start as number, deleteCount as number, ...(rest as Item[]));
    this.resort();
    return result;
  }

  resort() {
    if (!this._comparator) return;
    this.sort(this._comparator);
  }

  setItems(items: Item[]) {
    this.splice(0, this.length, ...items);
  }
}
