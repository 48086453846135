import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import ClubForm from "components/Forms/ClubForm";
import { injectModal } from "lib/injectParams";

/**
 * Model component for creating a club
 */
const NewClub: React.FC = () => {
  return (
    <AppModal title="New club">
      <ClubForm attributes={{}} />
    </AppModal>
  );
};

export default injectModal(ApplicationView(NewClub));
