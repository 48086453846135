import { IconComponent } from "./Icon";

const PlusIcon: IconComponent = ({ className }) => (
  <svg
    className={`${className}`}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0C7.164 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm0 30.032C8.28 30.032 2 23.72 2 16S8.28 2 16 2s14 6.28 14 14-6.28 14.032-14 14.032zM23 15h-6V9a1 1 0 00-2 0v6H9a1 1 0 000 2h6v6a1 1 0 002 0v-6h6a1 1 0 000-2z" />
  </svg>
);

export default PlusIcon;
