import { Tooltip } from "@mixitone/components";


const RatingTooltip: React.FC = () => {
  return (
    <Tooltip position="top" className="z-50 max-w-xs whitespace-break-spaces font-normal text-center" icon>
      <p>Ratings are calculated based on the player's performance in games.</p>
      <p>It can take up to an hour for the rating to update after a game.</p>
    </Tooltip>
  );
};

export default RatingTooltip;
