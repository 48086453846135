import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import ClubPlayer from "@mixitone/models/ClubPlayer";
import { ApplicationView } from "@mixitone/mvc";
import { isNil } from "@mixitone/util";
import clsx from "clsx";
import PlayerDot from "components/Pages/Night/PlayerDot";
import MixitoneController from "controllers/MixitoneController";
import { useCallback, useRef } from "react";

interface State {
  value: ClubPlayer | null;
  search: string;
  canRegisterFromSearch: boolean;
  filteredClubPlayers: ClubPlayer[];
  clubPlayers: ClubPlayer[];
  showInput?: boolean;
}
interface Props {
  value: ClubPlayer | null;
  onChange: (value: ClubPlayer | null) => void;
  onRegister?: (search: string) => void;
  canRegisterFromSearch: boolean;
  clubPlayers: ClubPlayer[];
}

class PlayerDropdownController extends MixitoneController<State, Props> {
  private onChange!: (value: ClubPlayer | null) => void;
  private onRegister?: (search: string) => void;

  override async initialize(props: Props) {
    this.onRegister = props.onRegister;
    this.onChange = props.onChange;

    this.state.value = props.value;
    this.state.canRegisterFromSearch = props.canRegisterFromSearch;
    this.state.clubPlayers = props.clubPlayers;
    this.state.filteredClubPlayers = [];
    this.actionSetSearch("");
    this.state.showInput = isNil(props.value);
  }

  override async changeProps(newProps: Props) {
    if (newProps.value?.id !== this.state.value?.id) {
      this.state.value = newProps.value;
      this.state.showInput = isNil(newProps.value);
    }
  }

  actionSetSearch(search: string) {
    this.state.search = search;

    if (search.length === 0) {
      this.state.filteredClubPlayers = this.state.clubPlayers.slice(0, 5);
    } else if (search.length < 3) {
      this.state.filteredClubPlayers = this.state.clubPlayers.filter((player) =>
        player.name?.toLowerCase().startsWith(search.toLowerCase()),
      );
    } else {
      this.state.filteredClubPlayers = this.state.clubPlayers.filter((player) => {
        return (
          (player.name && player.name.toLowerCase().includes(search.toLowerCase())) ||
          (player.email && player.email.toLowerCase().includes(search.toLowerCase())) ||
          (player.phone && player.phone.toLowerCase().includes(search.toLowerCase()))
        );
      });
    }
  }

  actionRegister() {
    if (this.onRegister) {
      this.onRegister(this.state.search);
    }
  }

  actionSetPlayer(player: ClubPlayer | null) {
    this.state.value = player;
    this.state.showInput = isNil(player);
    this.onChange(player);
  }
}

const PlayerDropdown = () => {
  const controller = PlayerDropdownController.use();
  const { canRegisterFromSearch, filteredClubPlayers, value, search, showInput } = controller.state;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    controller.state.showInput = true;

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 50);
  }, []);

  const options = filteredClubPlayers.map((player, idx) => {
    return (
      <ComboboxOption
        key={player.id}
        value={player}
        aria-roledescription=""
        className={clsx(
          "border-l border-r border-slate-300 p-4 ui-active:bg-brand-100 ui-active:text-black ui-not-active:bg-white ui-not-active:text-black",
          { "rounded-b-lg border-b": !canRegisterFromSearch && idx === filteredClubPlayers.length - 1 },
        )}
      >
        <PlayerDot player={player} inline />
      </ComboboxOption>
    );
  });

  return (
    // @ts-ignore
    <Combobox<ClubPlayer | null | "new">
      by={(a, b) => {
        if (a === "new" || b === "new") {
          return false;
        }
        return a?.id === b?.id;
      }}
      value={value}
      onChange={(v) => {
        if (v === "new") {
          return;
        }
        controller.actionSetPlayer(v);
      }}
      nullable
      immediate
    >
      <ComboboxInput
        ref={inputRef}
        data-testid="player-dropdown-input"
        autoComplete="off"
        autoCorrect="off"
        className={clsx("mt-1 block w-full rounded border border-gray-300 p-2", {
          hidden: !showInput,
        })}
        onChange={(event) => controller.actionSetSearch(event.target.value)}
        // onFocus={(event) => controller.actionSetSearch(event.target.value)}
        displayValue={(value: ClubPlayer) => value?.name || ""}
        placeholder="Select a player"
      />
      <ComboboxButton onClick={handleClick} className={clsx({ hidden: showInput })}>
        {value && <PlayerDot player={value} inline />}
      </ComboboxButton>
      <ComboboxOptions
        anchor="bottom start"
        className="relative z-50"
        style={{ width: "var(--input-width)" }}
      >
        {options}
        {canRegisterFromSearch && (
          <ComboboxOption
            key="new"
            value={"new"}
            className="rounded-b-lg border-b border-l border-r border-slate-300 p-4 ui-active:bg-brand-100 ui-active:text-black ui-not-active:bg-white ui-not-active:text-black"
            onSelect={() => controller.actionRegister()}
          >
            Register {search}
          </ComboboxOption>
        )}
      </ComboboxOptions>
    </Combobox>
  );
};

export default PlayerDropdownController.scope(ApplicationView(PlayerDropdown));
