import { Button } from "@mixitone/components";
import EditIcon from "@mixitone/components/icons/edit.svg";
import TrashIcon from "@mixitone/components/icons/trash.svg";
import { FillRule } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import FillRuleDescription from "./FillRuleDescription";
import { FillRuleListController } from "./FillRuleListController";

const FillRuleRow = ApplicationView(({ rule, index }: { rule: FillRule; index: number }) => {
  const controller = FillRuleListController.use();
  const { club } = controller.state;

  const handleDelete: React.MouseEventHandler = (e) => {
    e.preventDefault();
    controller.actionDeleteRule(rule);
  };

  return (
    <tr className="border-b border-slate-200 text-sm *:py-2">
      <td>{index + 1}</td>
      <td>
        <FillRuleDescription rule={rule} />
      </td>
      <td className="text-right">
        {club.hasManagePermission() && (
          <div className="flex items-center justify-end gap-2">
            <Button
              onClick={(e) => {
                e.preventDefault();
                controller.actionOpenModal("/clubs/:clubId/groups/rules/:id/edit", {
                  params: { clubId: rule.club_id!, id: rule.id! },
                });
              }}
              kind="plain"
            >
              <EditIcon className="h-[19px] w-[19px] stroke-gray-800" />
            </Button>
            <Button kind="plain" onClick={handleDelete}>
              <TrashIcon className="h-[19px] w-[19px]" />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
});

export default FillRuleRow;
