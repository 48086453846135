import { Toggle, Tooltip } from "@mixitone/components";
import AccountUser from "@mixitone/models/AccountUser";
import Club from "@mixitone/models/Club";
import ClubPermission from "@mixitone/models/ClubPermission";
import { ApplicationView } from "@mixitone/mvc";
import { useCallback } from "react";

type PermissionKey = keyof ClubPermission & ("view_club" | "admin_club" | "manage_club" | "manage_sessions");

const PermissionToggle = ApplicationView(
  ({
    disabled,
    overrideChecked,
    permission,
    perm,
  }: {
    disabled: boolean;
    overrideChecked?: boolean;
    permission: ClubPermission;
    perm: PermissionKey;
  }) => {
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      permission[perm] = e.target.checked;
    }, []);

    return (
      <td>
        <div className="flex items-center justify-center">
          <Toggle
            disabled={disabled}
            small
            checked={overrideChecked || (permission[perm] ?? false)}
            onChange={handleChange}
          />
        </div>
      </td>
    );
  },
);

interface Props {
  accountUser?: AccountUser;
  club: Club;
  permission: ClubPermission;
}

const ClubPermissionRow: React.FC<Props> = ({ accountUser, club, permission }) => {
  const isAccountAdmin = accountUser?.isAdmin();
  const isClubAdmin = AccountUser.current?.clubPermissionFor(club.id!)?.admin_club;
  const disabled = isAccountAdmin || !isClubAdmin;
  const overrideChecked = isAccountAdmin ? true : undefined;

  return (
    <tr key={club.domKey()} className="*:px-1 *:py-1 even:bg-slate-50">
      <td>{club.name}</td>
      <td>
        {!isClubAdmin && <Tooltip icon>You do not have admin access to change these permissions</Tooltip>}
      </td>
      {["view_club", "admin_club", "manage_club", "manage_sessions"].map((perm) => (
        <PermissionToggle
          key={perm}
          disabled={disabled}
          overrideChecked={overrideChecked}
          permission={permission}
          perm={perm as PermissionKey}
        />
      ))}
    </tr>
  );
};

export default ApplicationView(ClubPermissionRow);
