import { GroupDot, Modal, Spinner } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import ClubPlayerForm from "components/Forms/ClubPlayerForm";
import TickIcon from "components/icons/TickIcon";
import QrScannerController from "components/modals/QrScanner/QrScannerController";
import { injectModal } from "lib/injectParams";

const QrScanner = () => {
  const controller = QrScannerController.use();
  const { scanning, accepted, tokenId, club, registeredPlayers } = controller.state;

  return (
    <>
      <Modal
        onClose={() => controller.actionStopScan()}
        title="Register"
        className="absolute"
        style={{ top: "calc(50%-175px)" }}
      >
        <div
          className={clsx("-mx-3 -mt-4 overflow-hidden sm:-mx-6 sm:-mb-6 sm:w-[400px]", {
            hidden: tokenId,
            "border-2 border-green-400": accepted,
          })}
        >
          <div className="relative overflow-hidden sm:h-[350px] sm:w-[400px]">
            <video
              ref={(el) => controller.actionSetScannerContainer(el)}
              className={clsx("h-full w-full object-cover", {})}
              autoPlay
              playsInline
              muted
              data-scanning={scanning}
            ></video>
            <button onClick={() => controller.actionTest()} className="absolute right-0 top-0">
              test
            </button>
            {(!scanning || accepted) && (
              <Spinner size={60} className="absolute left-1/2 top-1/2 ml-[-30px] mt-[-30px]" />
            )}
          </div>

          <div className="flex flex-col divide-y divide-gray-300">
            {registeredPlayers.map((player, idx) => (
              <div key={`registered-${player.id}-${idx}`} className="flex items-center justify-between p-2">
                <div className="flex items-center gap-2">
                  <TickIcon className="h-4 w-4 stroke-green-600" />
                  Registered player
                </div>

                <div className="flex items-center gap-2">
                  <span>{player.name}</span>
                  <GroupDot group={player.group} size={13} />
                </div>
              </div>
            ))}
          </div>
        </div>
        {tokenId && (
          <div>
            <div
              className={clsx(
                "w-full rounded-md border border-yellow-800 p-4 transition duration-500 ease-in-out",
                "flex justify-between",
                "bg-yellow-100 text-black",
              )}
            >
              The scanned code will be associated with this player.
            </div>
            <ClubPlayerForm
              attributes={{ club_id: club.id }}
              search
              onSubmit={(clubPlayer) => {
                return controller.actionRegisterTokenTo(clubPlayer);
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default injectModal(QrScannerController.scope(ApplicationView(QrScanner)));
