import { Button, EmptyState, Input, LabelledField, bindController } from "@mixitone/components";
import NoWifiIcon from "@mixitone/components/icons/no-wifi.svg";
import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import { InviteAccountUserController } from "components/modals/InviteAccountUser/InviteAccountUserController";
import UserPermissionsTable from "components/modals/UserPermissions/UserPermissionsTable";
import { injectModal } from "lib/injectParams";

const InviteAccountUser = () => {
  const controller = InviteAccountUserController.use();
  const { creating } = controller.state;
  const bind = bindController(controller);

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    controller.actionSubmit();
  };

  if (!navigator.onLine) {
    return (
      <AppModal title="Invite an administrator">
        <EmptyState Icon={NoWifiIcon} size="lg">
          <p>You are currently working offline.</p>
          <p>Connect to the Internet to invite people to your account.</p>
          <Button onClick={() => controller.actionBack()}>Close</Button>
        </EmptyState>
      </AppModal>
    );
  }

  return (
    <AppModal title="Invite an administrator">
      <p>Invite another person to help manage your clubs.</p>

      <form onSubmit={handleOnSubmit}>
        <fieldset disabled={creating}>
          <LabelledField label="Email" htmlFor="email">
            <Input autoFocus required type="email" {...bind("email")} />
          </LabelledField>
        </fieldset>

        <UserPermissionsTable
          clubs={controller.state.clubs}
          permissionsByClub={controller.state.indexedPermissions}
        />

        <div className="mt-4 flex items-center justify-end">
          <Button type="submit" disabled={creating} spinner={creating}>
            Send invite
          </Button>
        </div>
      </form>
    </AppModal>
  );
};

export default injectModal(InviteAccountUserController.scope(ApplicationView(InviteAccountUser)));
