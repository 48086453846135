import { ModelRegistry } from "./modelRegistry";
import { isNil, isNilSymbol, isPresent } from "@mixitone/util";
import { Model, ModelConstructor } from "./model";
import { observable } from "@nx-js/observer-util";

export type ReferenceDescriptor<M extends Model<any>> = {
  model: () => ModelConstructor<M>;
  key: string;
};
export type References = {
  [index: string]: ReferenceDescriptor<any>;
};

export function loadReference<M extends Model<any>>(model: ModelConstructor<M>, id: string | undefined) {
  if (isNil(id)) return undefined;

  return ModelRegistry.instance.get(model, id);
}

export type Loadable<M> = M & {
  [isNilSymbol]: boolean;
  load: () => Promise<Loadable<M>>;
  reloadIfLoaded: () => Promise<Loadable<M>>;
  reload: () => Promise<Loadable<M>>;
  unload: () => void;
  loaded: boolean;
  set(value: M): void;
  value: M | undefined;
};

function wrapInLoadable<M extends Model<any>>(
  get: () => M | undefined,
  load: () => Promise<M | null | undefined>,
) {
  const value = get();
  const proxyData = observable({ value, loaded: isPresent(value) });

  const setFn = (value: M) => {
    proxyData.value = value;
    proxyData.loaded = true;
  };

  const proxy = new Proxy(proxyData, {
    getPrototypeOf(target) {
      if (!target.value) return null;
      return Reflect.getPrototypeOf(target.value);
    },
    set(target, p, newValue, receiver) {
      if (!target.value) {
        target.value = get();
        target.loaded = isPresent(target.value);
      }
      if (target.value) {
        return Reflect.set(target.value, p, newValue, receiver);
      }

      throw new Error("Loadable not loaded");
    },
    get(target, prop) {
      switch (prop) {
        case isNilSymbol: {
          if (target.value) return false;
          target.value = get();
          target.loaded = isPresent(target.value);
          return isNil(target.value);
        }
        case "unload": {
          return () => {
            target.value = get();
            target.loaded = isPresent(target.value);
          };
        }
        case "load": {
          return async () => {
            const newValue = await load();
            // @ts-ignore
            target.value = newValue;
            target.loaded = isPresent(newValue);
            return proxy;
          };
        }
        case "reloadIfLoaded": {
          return async () => {
            if (target.loaded) {
              const newValue = await load();
              // @ts-ignore
              target.value = newValue;
              target.loaded = isPresent(newValue);
            }
            return proxy;
          };
        }
        case "reload": {
          return async () => {
            const newValue = await load();
            // @ts-ignore
            target.value = newValue;
            target.loaded = isPresent(newValue);
            return proxy;
          };
        }
        case "loaded": {
          return target.loaded;
        }
        case "set": {
          return setFn;
        }
        case "value": {
          return target.value;
        }
        default: {
          if (!target.value) {
            target.value = get();
            target.loaded = isPresent(target.value);
          }
          if (!target.value) return undefined;
          if (!target.loaded) throw new Error("Loadable not loaded");
          return Reflect.get(target.value, prop);
        }
      }
    },
  });

  return proxy as unknown as Loadable<M>;
}

export function loadableReference<T extends Model<any>, M extends Model<any>>(
  target: T,
  model: ModelConstructor<M>,
  key: keyof T,
): Loadable<M> {
  const get = () => {
    const id = target[key] as string | undefined;
    if (isNil(id)) return undefined;
    return ModelRegistry.instance.get(model, id);
  };

  const load = async () => {
    const id = target[key] as string | undefined;
    if (isNil(id)) return undefined;
    return model.query().find(id);
  };

  return wrapInLoadable(get, load);
}
