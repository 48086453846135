import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Button, LoadingModal } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import AppModal from "components/common/AppModal";
import FillRulesList from "components/modals/Sessions/Settings/GroupList/FillRulesList";
import GroupList from "components/modals/Sessions/Settings/GroupList/GroupList";
import SessionSettings from "components/modals/Sessions/Settings/SessionSettings";
import { SettingsController } from "components/modals/Sessions/Settings/SettingsController";
import { injectModal } from "lib/injectParams";
import React, { useCallback, useEffect, useState } from "react";

const tabs = ["Session", "Groups", "Rules"];

const Settings: React.FC = () => {
  const controller = SettingsController.use();
  const { updating, defaultTab, loading, night, club } = controller.state;
  const defaultIndex = tabs.includes(defaultTab) ? tabs.indexOf(defaultTab) : 0;
  const [currentIndex, setCurrentIndex] = useState(defaultIndex);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    controller.actionSubmit();
  };

  useEffect(() => {
    setCurrentIndex(defaultIndex);
  }, [defaultIndex]);

  const handleTabChange = useCallback((index: number) => {
    setCurrentIndex(index);
    controller.actionSetDefaultTab(tabs[index]);
  }, []);

  const handleSave = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    controller.actionSubmit();
  }, []);

  if (loading || !night) {
    return <LoadingModal title="Settings" className="h-full min-h-[80%] sm:h-[80%] md:min-w-[700px]" />;
  }

  return (
    <AppModal title="Session settings" className="h-full min-h-[80%] sm:h-[80%] md:min-w-[700px]">
      <form
        onSubmit={handleSubmit}
        className="flex flex-grow flex-col justify-between gap-5 overflow-y-hidden"
      >
        <div className={clsx("flex flex-grow flex-col justify-between gap-5 overflow-y-hidden")}>
          <div className={clsx("flex flex-grow flex-col overflow-y-hidden")}>
            <TabGroup
              className="h-full overflow-y-hidden"
              onChange={handleTabChange}
              defaultIndex={defaultIndex}
              selectedIndex={currentIndex}
            >
              <TabList className="flex space-x-1 rounded-xl bg-brand-800 p-1">
                {tabs.map((name) => (
                  <Tab
                    key={name}
                    className={({ selected }) =>
                      clsx(
                        "w-full rounded-lg py-2.5 font-medium leading-5 text-brand-700",
                        "focus:outline-none",
                        {
                          "bg-white shadow": selected,
                          "text-white hover:bg-white/[0.12] hover:text-white": !selected,
                        },
                      )
                    }
                  >
                    {name}
                  </Tab>
                ))}
              </TabList>
              <TabPanels className="h-full flex-grow overflow-y-hidden px-4 pb-10 pt-5">
                <TabPanel>
                  <SessionSettings />
                </TabPanel>
                <TabPanel className="relative h-full overflow-y-auto">
                  {() => (
                    <div className="h-full">
                      <GroupList club={club} />
                    </div>
                  )}
                </TabPanel>
                <TabPanel className="relative h-full overflow-y-auto">
                  {() => <FillRulesList club={club} />}
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>

          <div className="flex justify-end">
            {club.hasSessionPermission() && (
              <Button onClick={handleSave} disabled={updating} spinner={updating}>
                Save
              </Button>
            )}
          </div>
        </div>
      </form>
    </AppModal>
  );
};

export default injectModal(SettingsController.scope(ApplicationView(Settings)));
