import { LoadingModal } from "@mixitone/components";
import Night from "@mixitone/models/Night";
import { ApplicationView } from "@mixitone/mvc";
import { convertMillisecondsToReadableDuration } from "@mixitone/util";
import StatsImg from "assets/stats.svg";
import AppModal from "components/common/AppModal";
import MixitoneController from "controllers/MixitoneController";
import { injectModal } from "lib/injectParams";

interface State {
  night: Night;
  loading: boolean;
}

interface Props {
  token: string;
}

class StatsController extends MixitoneController<State, Props> {
  get initialState(): State {
    return {
      night: new Night({}),
      loading: true,
    };
  }

  override async initialize(props: Props) {
    this.state.night = await Night.query().eq("id", props.token).waitFirst();
    this.state.loading = false;
  }
}

const Stats: React.FC = () => {
  const controller = StatsController.use();
  const { night, loading } = controller.state;

  if (loading) return <LoadingModal title="Session stats" className="h-[200px] bg-slate-800 md:w-[600px]" />;

  const totalDuration = night.game_sets.map((set) => set.duration).reduce((a, b) => a + b, 0);
  const averageDuration =
    totalDuration > 0 && night.game_sets.length > 0 ? totalDuration / night.game_sets.length : 0;

  const stats = {
    playerCount: night.players.length,
    numberOfSets: night.game_sets.length,
    totalDuration: convertMillisecondsToReadableDuration(totalDuration),
    averageDuration: convertMillisecondsToReadableDuration(averageDuration),
    totalPaid: night.player_fee
      ? // ? night.players.filter((player) => player.paid).length * night.player_fee
        night.players.length * night.player_fee
      : null,
    // playersNotPaid: night.players.filter((player) => !player.paid),
  };

  return (
    <AppModal title="Session stats" className="bg-slate-800 md:w-[600px]">
      <div className="grid grid-cols-2 gap-4 text-white">
        <div className="col-span-full mb-10 mt-4 text-center">
          <StatsImg className="mx-auto h-[100px]" />
        </div>
        <div className="flex justify-between">
          <strong>Player Count:</strong> <span>{stats.playerCount}</span>
        </div>
        <div className="flex justify-between">
          <strong>Number of Sets:</strong> <span>{stats.numberOfSets}</span>
        </div>
        <div className="flex justify-between">
          <strong>Total Duration:</strong> <span>{stats.totalDuration}</span>
        </div>
        <div className="flex justify-between">
          <strong>Average Duration:</strong> <span>{stats.averageDuration}</span>
        </div>
        {(night.player_fee || 0) > 0 && (
          <>
            <div className="flex justify-between">
              <strong>Total Paid:</strong> <span>${stats.totalPaid}</span>
            </div>
            {/* <div className="col-span-full">
              <strong>Paying next week:&nbsp;</strong>
              <span>
                {stats.playersNotPaid.length === 0
                  ? " Everyone has paid!"
                  : stats.playersNotPaid.map((p) => p.name!).join(", ")}
              </span>
            </div> */}
          </>
        )}
      </div>
    </AppModal>
  );
};

export default injectModal(StatsController.scope(ApplicationView(Stats)));
