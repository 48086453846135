import { LoadingModal } from "@mixitone/components";
import { Night } from "@mixitone/models";
import RecurringSession from "@mixitone/models/RecurringSession";
import { ApplicationView } from "@mixitone/mvc";
import { List } from "@mixitone/oom";
import { formatDate, formatNumber, withStateToggle } from "@mixitone/util";
import AppModal from "components/common/AppModal";
import { RoutedLink } from "components/common/Link";
import MixitoneController from "controllers/MixitoneController";
import { injectModal } from "lib/injectParams";

interface Props {
  clubId: string;
  recurringSessionId?: string;
}

interface State {
  clubId: string;
  recurringSessionId?: string;
  event?: RecurringSession;
  nights: List<Night>;
  loading: boolean;
}

class ListEventsController extends MixitoneController<State, Props> {
  override get initialState(): State {
    return {
      clubId: "",
      nights: new List(),
      loading: true,
    };
  }

  override async initialize(props: Props) {
    this.changeProps(props);
  }

  override async changeProps(props: Props) {
    this.state.recurringSessionId = props.recurringSessionId;
    this.state.clubId = props.clubId!;
    await this.refresh();
  }

  @withStateToggle("loading")
  async refresh() {
    if (this.state.recurringSessionId) {
      this.state.event = await RecurringSession.query()
        .eq("club_id", this.state.clubId)
        .find(this.state.recurringSessionId);
    } else {
      this.state.event = undefined;
    }

    let query = Night.query().limit(100).orderBy("date", "desc").preloadCounts("players", "game_sets");

    if (this.state.event) {
      query = query.eq("session_id", this.state.event.id);
    } else {
      query = query.eq("club_id", this.state.clubId);
    }

    this.state.nights = new List(query);
  }
}

const ListSessions: React.FC<Props> = () => {
  const controller = ListEventsController.use();
  const { loading, nights } = controller.state;
  const title = "Session history";

  if (loading) return <LoadingModal title={title} />;

  const nightsList = nights.map((night) => {
    const totalPaid = (night.player_fee || 0) * night.players.count();

    return (
      <tr key={`session-${night.id}`}>
        <td className="py-1">
          <RoutedLink kind="plain" to="/t/:token" params={{ token: night.id! }}>
            {formatDate(night.date)}
          </RoutedLink>
        </td>
        <td className="py-1 text-center">{night.players.count()}</td>
        <td className="py-1 text-center">${formatNumber(totalPaid)}</td>
        <td className="py-1 text-center">{night.game_sets.count()}</td>
      </tr>
    );
  });

  return (
    <AppModal title={title}>
      <table>
        <thead>
          <tr className="border-b border-gray-200">
            <th className="py-1 text-left">Date</th>
            <th className="py-1 text-center">Players</th>
            <th className="py-1 text-center">Paid</th>
            <th className="py-1 text-center">Sets</th>
          </tr>
        </thead>
        <tbody>{nightsList}</tbody>
      </table>
    </AppModal>
  );
};

export default injectModal(ListEventsController.scope(ApplicationView(ListSessions)));
