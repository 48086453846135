import { FloatingArrow, arrow, shift, useFloating } from "@floating-ui/react";
import { Button, DropdownMenu, DropdownMenuItem, GroupDot } from "@mixitone/components";
import Group from "@mixitone/models/Group";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import TickIcon from "components/icons/TickIcon";
import { useCallback, useRef, useState } from "react";
import { PlayerDrawerController } from "./PlayerDrawerController";

export const GroupDropdown = ApplicationView(({ value }: { value: Group }) => {
  const playerController = PlayerDrawerController.use();
  const { groups } = playerController.state.club;
  const [open, setOpen] = useState(false);
  const [updated, setUpdated] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, update, context } = useFloating({
    middleware: [shift(), arrow({ element: arrowRef })],
  });

  const handleItemClick = useCallback((group: Group) => {
    setOpen(false);
    playerController.actionMoveToGroup(group).then(() => {
      setUpdated(true);
      setTimeout(() => setUpdated(false), 1000);
    });
  }, []);

  return (
    <>
      <div className="flex items-center gap-1">
        <Button
          kind="dropdown"
          ref={refs.setReference}
          onClick={() => {
            update();
            setOpen(true);
          }}
        >
          <div className="flex items-center gap-2">
            <GroupDot group={value} size={24} />
            {value.name}
          </div>
        </Button>
        <TickIcon
          className={clsx("w-[10px] stroke-green-600 transition-opacity", {
            "duration-500": !updated,
            "duration-0": updated,
            "opacity-100": updated,
            "opacity-0": !updated,
          })}
        />
      </div>
      <DropdownMenu
        dropRef={refs.setFloating}
        open={open}
        onClose={() => setOpen(false)}
        style={floatingStyles}
      >
        <FloatingArrow ref={arrowRef} context={context} fill="white" />
        {groups.map((group) => (
          <DropdownMenuItem
            key={group.domKey()}
            className="flex items-center gap-2 hover:no-underline"
            onClick={handleItemClick}
            value={group}
          >
            <GroupDot group={group} size={20} />
            {group.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </>
  );
});
