import { Model, ModelAttributes, collection, model, modelConfig, reference } from "@mixitone/oom";
import { Loadable } from "@mixitone/oom/reference";
import { isNil } from "@mixitone/util";
import Club from "./Club";
import ClubPlayerToken from "./ClubPlayerToken";
import Group from "./Group";
import Note from "./Note";
import { setAccountId } from "./concerns/SetAccountId";
import { ValidationError } from "@mixitone/oom";
import PlayerRating from "./PlayerRating";
import Player from "./Player";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    account_user_id: {
      type: "string",
    },
    active: {
      type: "boolean",
    },
    club_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    email: {
      type: "string",
    },
    group_id: {
      type: "string",
    },
    is_deleted: {
      type: "boolean",
    },
    latest_rating: {
      type: "number",
    },
    latest_rating_date: {
      type: "string",
    },
    name: {
      type: "string",
    },
    partner_id: {
      type: "string",
    },
    phone: {
      type: "string",
    },
    player_profile_id: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
  },
  {
    notes: collection(() => Note, "club_player_id", {
      order: "created_at",
    }),
    club_player_tokens: collection(() => ClubPlayerToken, "club_player_id"),
    ratings: collection(() => PlayerRating, "club_player_id"),
    players: collection(() => Player, "club_player_id"),
  },
  {
    club: reference<any>(() => Club as any, "club_id"),
    group: reference<any>(() => Group as any, "group_id"),
  },
);

interface ClubPlayer extends ModelAttributes<typeof config> {
  get group(): Loadable<Group>;
  set group(value: Group | undefined);

  get club(): Loadable<Club>;
  set club(value: Club | undefined);
}

@setAccountId
@model("club_players", config)
class ClubPlayer extends Model<typeof config> {
  get noteCount() {
    if (this.notes.loaded) {
      return this.notes.length;
    } else {
      const notesData = this.data.get("notes");
      if (Array.isArray(notesData) && !isNil(notesData[0]?.count)) {
        return notesData[0].count as number;
      }
    }

    return 0;
  }

  override async create() {
    if (isNil(this.name)) {
      throw new ValidationError({ name: "cannot be blank" });
    }

    if (isNil(this.group_id)) {
      await this.club.load();
      await this.club.ensureGroups();
      this.group_id = this.club.groups[0].id;
    }
    return super.create();
  }

  get ratingOrDefault() {
    return this.latest_rating ?? 1500;
  }
}

export default ClubPlayer;
