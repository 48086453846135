export * from "./model";
export * from "./collection";
export * from "./query";
export * from "./modelRegistry";
export * from "./config";
export * from "./reference";
export * from "./validation";
export * from "./List";
export * from "./subscribeList";

interface OOMConfig {
  adapter: any;
  exceptionHandler: (error: Error, extra?: Record<string, any>) => void;
}

interface OOM {
  config: OOMConfig;
  configure(config: Partial<OOMConfig>): void;
  captureException(error: Error, extra?: Record<string, any>): void;
}

export const oom: OOM = {
  config: {
    adapter: null,
    exceptionHandler: () => {}, // default no-op handler
  },

  configure(config: Partial<OOMConfig>) {
    this.config = { ...this.config, ...config };
  },

  captureException(error: Error, extra?: Record<string, any>) {
    this.config.exceptionHandler(error, extra);
  },
};
