import { Button, LoadingModal, Modal } from "@mixitone/components";
import PlusIcon from "@mixitone/components/icons/plus.svg";
import { IncidentLog, Night } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import { List, subscribeList } from "@mixitone/oom";
import IncidentLogForm from "components/Forms/IncidentLogForm";
import MixitoneController from "controllers/MixitoneController";
import dayjs from "dayjs";
import { injectModal } from "lib/injectParams";
import { Fragment } from "react";
import Markdown from "react-markdown";

interface State {
  loading: boolean;
  logs: List<IncidentLog>;
  showForm?: boolean;
  formId?: string;
  night: Night;
}

interface Props {
  token: string;
}

export class IncidentLogController extends MixitoneController<State, Props> {
  get initialState(): State {
    return {
      loading: true,
      logs: new List(),
      night: new Night({}),
      showForm: false,
    };
  }

  async initialize(props: Props) {
    this.state.loading = true;

    const night = await Night.query().find(props.token);
    const query = IncidentLog.query().eq("night_id", night?.uniqueId).preload("loggedByAccountUser");
    const logs = await query.all();

    this.state.night = night!;
    this.state.logs = new List(...logs);
    this.addDependency(await subscribeList(this.state.logs, query));
    this.state.loading = false;
  }

  actionShowIncident(id: string) {
    this.state.formId = id;
    this.actionShowForm();
  }

  actionShowForm() {
    this.state.showForm = true;
  }

  actionHideForm() {
    this.state.showForm = false;
    this.state.formId = undefined;
  }

  actionClose() {
    if (this.state.showForm) {
      this.actionHideForm();
    } else {
      this.actionBack();
    }
  }
}

const LogItem = ApplicationView(({ log }: { log: IncidentLog }) => {
  const controller = IncidentLogController.use();

  return (
    <div onClick={() => controller.actionShowIncident(log.id!)}>
      <p className="my-0 font-semibold text-white">
        Incident occurred at{" "}
        {log.incident_occurred_at
          ? dayjs(log.incident_occurred_at).format("h:ma D MMM YYYY")
          : "Unknown Date"}
        , logged by {log.loggedByAccountUser.name}.
      </p>
      <div className="mt-1 prose text-white">
        <Markdown>{log.incident_description ?? "No description provided."}</Markdown>
      </div>
    </div>
  );
});

const Log: React.FC = () => {
  const controller = IncidentLogController.use();
  const { loading, logs, showForm, night } = controller.state;

  if (loading) return <LoadingModal title="Incident log" className="h-[200px] bg-slate-800 md:w-[600px]" />;

  return (
    <Modal
      title="Incident log"
      className="bg-slate-800 md:w-[600px]"
      onClose={() => controller.actionClose()}
    >
      {!showForm ? (
        <Fragment>
          <div className="flex flex-col mb-10 gap-y-5">
            {logs.length > 0 ? (
              logs.map((log) => <LogItem log={log} key={log.id} />)
            ) : (
              <div className="container p-4 mx-auto text-center">
                <p className="text-slate-200">No incident logs for this session.</p>
              </div>
            )}
          </div>
          <Button
            className="mx-auto"
            onClick={() => {
              controller.actionShowForm();
            }}
          >
            <PlusIcon className="mr-1 w-[12px]" />
            New incident
          </Button>
        </Fragment>
      ) : null}
      {showForm ? (
        <IncidentLogForm
          id={controller.state.formId}
          onSubmit={async () => controller.actionHideForm()}
          onDelete={async () => controller.actionHideForm()}
          attributes={{ night_id: night.uniqueId }}
        />
      ) : null}
    </Modal>
  );
};

export default injectModal(IncidentLogController.scope(ApplicationView(Log)));
