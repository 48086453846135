import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import RecurringSessionForm from "components/Forms/RecurringSessionForm";
import { injectModal } from "lib/injectParams";

interface Props {
  clubId: string;
}

const NewEvent: React.FC<Props> = ({ clubId }) => {
  return (
    <AppModal title="New time">
      <RecurringSessionForm attributes={{ club_id: clubId || "" }} />
    </AppModal>
  );
};

export default injectModal(ApplicationView(NewEvent));
