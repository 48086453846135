import { Button } from "@mixitone/components";
import { ApplicationView, useController } from "@mixitone/mvc";
import { FormController } from "./FormController";


const FormButtons: React.FC = () => {
  const controller = useController(FormController);
  const { canDelete } = controller;
  const { saving } = controller.state;

  return (
    <div className="flex items-center justify-end gap-2">
      {canDelete && (
        <Button type="button" kind="danger" disabled={saving} onClick={() => controller.actionDelete()}>
          Delete
        </Button>
      )}
      <Button type="submit" disabled={saving} spinner={saving}>
        Save
      </Button>
    </div>
  );
};

export default ApplicationView(FormButtons);
