import Debug from "debug";
import { GetModelAttributes, List, Model, Query, SubscriptionCallbackPayload } from "./";

const debug = Debug("subscribeList");

async function insertToList<M extends Model<any>>(list: List<M>, model: M, query: Query<M, any>) {
  if (query.hasPreloads) {
    await query.loadPreloads([model]);
  }

  list.insert(model);
}

export function subscribeListHandler<M extends Model<any>>(
  list: List<M>,
  query: Query<M, any>,
  data: SubscriptionCallbackPayload<M>,
) {
  switch (data.type) {
    case "INSERT":
      if (query.matches(data.model)) {
        debug("inserting", data.model.rawAttributes);
        insertToList(list, data.model, query);
      }
      break;
    case "UPDATE":
      const listIndex = list.findIndexById(data.model.uniqueId);

      if (listIndex >= 0) {
        if (!query.matches(data.model)) {
          debug("update caused remove", data.model.rawAttributes);
          list.remove(data.model);
        } else {
          const item = list[listIndex];
          item.updateFromSubscription(data.model.rawAttributes, data.source);
          list.resort();
        }
      } else if (query.matches(data.model)) {
        debug("update caused insert", data.model.rawAttributes);
        insertToList(list, data.model, query);
      } else {
        debug("update not in query", data.model.rawAttributes);
      }
      break;
    case "DELETE":
      debug("removing", data.model.rawAttributes);
      list.remove(data.model);
      break;
  }
}

/**
 * Subscribe a list to a query. When realtime changes are made, they are checked
 * against the query and if they match then the list is mutated.
 */
export function subscribeList<M extends Model<any>>(
  list: List<M>,
  query: Query<M, any>,
  filter?: Partial<GetModelAttributes<M>>,
) {
  return query.subscribe((data) => {
    subscribeListHandler(list, query, data);
  }, filter);
}
