import ApplicationController from "@mixitone/mvc";
import { Location } from "react-router-dom";
import { Path, useModals, useNavigate, useParams } from "../router";
import { To } from "@mixitone/router";

interface State {
  navigate: ReturnType<typeof useNavigate>;
  modals: ReturnType<typeof useModals>;
  location: Location;
  urlAfterLogin?: Path;
  params: ReturnType<typeof useParams>;
  ready?: boolean;
}
interface Props {
  navigate: ReturnType<typeof useNavigate>;
  modals: ReturnType<typeof useModals>;
  location: Location;
  params: ReturnType<typeof useParams>;
}

type NavigateType = ReturnType<typeof useNavigate>;
type ModalsType = ReturnType<typeof useModals>;

export default class RouteController extends ApplicationController<State, {}> {
  async initialize(props: never) {
    // @ts-ignore this is a hack to make the controller available to components
    window.routeController = this;
  }

  actionSetup(props: Props) {
    this.state.location = props.location;
    this.state.navigate = props.navigate;
    this.state.params = props.params;
    this.state.modals = props.modals;
    this.state.ready = true;
  }

  get params(): Record<string, string | undefined> {
    return this.state.params;
  }

  get modalParams(): Record<string, string | undefined> {
    return this.state.location.state || {};
  }

  async actionBack() {
    console.log("back");
    this.state.navigate(-1);
  }

  /**
   * @example
   * controller.actionOpenModal("modalName", { state: { foo: "bar" } });
   */
  public actionOpenModal: ModalsType["open"] = (...args) => {
    const [modal, options] = args;
    this.state.modals.open(modal, { ...options });
  };

  public actionCloseModal: ModalsType["close"] = (...args) => {
    const options = args[0];
    this.state.modals.close({ ...options });
  };

  actionForward<P extends Path | To<Path> | number>(...args: Parameters<NavigateType>) {
    // @ts-ignore
    this.state.navigate<P>(...args);
  }

  setUrlAfterLogin(url: Path | undefined) {
    this.state.urlAfterLogin = url;
  }
}
