import { ApplicationView } from "@mixitone/mvc";
import GroupListController from "./GroupListController";
import { ModalAlert } from "@mixitone/components";

const DeleteAlert = () => {
  const controller = GroupListController.use();
  const { deleteGroup, defaultGroup, deleting } = controller.state;

  if (!deleteGroup) return null;

  return (
    <ModalAlert
      message={
        defaultGroup ? (
          <span>
            <span>Are you sure you want to delete this group? </span>
            {deleteGroup.players.count() > 0 && (
              <span>
                {deleteGroup.players.count()} players in this group will be moved to group{" "}
                {defaultGroup?.name}.
              </span>
            )}
            {deleteGroup.players.count() === 0 && <span>There are no players in this group.</span>}
          </span>
        ) : (
          <span>You cannot delete the last group.</span>
        )
      }
      onConfirm={() => controller.actionConfirmDeleteGroup()}
      loading={deleting}
      title={`Delete ${deleteGroup.name}`}
      onCancel={function (): void {
        controller.actionCancelDeleteGroup();
      }}
      confirmText={defaultGroup ? "Yes" : null}
      cancelText={defaultGroup ? "No" : "OK"}
    />
  );
};

export default ApplicationView(DeleteAlert);
