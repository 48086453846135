import { AccountUser, Club, ClubPermission } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import ClubPermissionRow from "./ClubPermissionRow";

interface Props {
  accountUser?: AccountUser;
  clubs: Club[];
  permissionsByClub: Record<string, ClubPermission>;
}

const UserPermissionsTable: React.FC<Props> = ({ accountUser, clubs, permissionsByClub }) => {
  return (
    <table className="w-full">
      <colgroup>
        <col className="w-[30%]" />
        <col />
        <col className="w-[17%]" />
        <col className="w-[17%]" />
        <col className="w-[17%]" />
        <col className="w-[17%]" />
      </colgroup>

      <thead>
        <tr className="text-xs uppercase text-gray-500 *:px-2">
          <th className="text-left">Name</th>
          <th></th>
          <th>View</th>
          <th>Admin</th>
          <th>Manage players</th>
          <th>Manage sessions</th>
        </tr>
      </thead>

      <tbody className="divide-y divide-gray-200">
        {clubs.map((club) => (
          <ClubPermissionRow
            key={club.domKey()}
            accountUser={accountUser}
            club={club}
            permission={permissionsByClub[club.uniqueId]}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ApplicationView(UserPermissionsTable);
