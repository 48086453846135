import { DrawerCard, DrawerField, DrawerTitle } from "@mixitone/components";
import CalendarIcon from "@mixitone/components/icons/calendar.svg";
import { ApplicationView } from "@mixitone/mvc";
import { formatNumber } from "@mixitone/util";
import { RoutedDrawer } from "components/common/RoutedDrawer";
import PlayerDot from "components/Pages/Night/PlayerDot";
import dayjs from "dayjs";
import { injectModal } from "lib/injectParams";
import { SessionDrawerController } from "../SessionDrawerController";

interface Props {
  clubId: string;
  id: string;
}

const SessionDrawer = (_props: Props) => {
  const controller = SessionDrawerController.use();
  const { loading, night, newPlayers, totalGames, totalSets } = controller.state;

  const totalCollected = night.player_fee ? night.player_fee * (night.players?.length ?? 0) : 0;

  return (
    <RoutedDrawer spinner={loading}>
      <DrawerTitle icon={<CalendarIcon className="mr-2 w-[36px] stroke-gray-500" />}>
        Session Details
      </DrawerTitle>
      {loading ? (
        <div className="flex animate-pulse flex-col gap-4">
          <DrawerCard>
            <DrawerField label="Date">&nbsp;</DrawerField>
            <hr />
            <DrawerField label="Total Players">&nbsp;</DrawerField>
            <hr />
            <DrawerField label="Game Sets">&nbsp;</DrawerField>
            <DrawerField label="Total Games">&nbsp;</DrawerField>
            <hr />
            <DrawerField label="New Players">
              <div className="h-4 w-32 bg-gray-100 rounded">&nbsp;</div>
            </DrawerField>
          </DrawerCard>
        </div>
      ) : (
        <DrawerCard>
          <DrawerField label="Date">
            {dayjs(night.date).format("MMMM D, YYYY")}
          </DrawerField>
          <hr />
          <DrawerField label="Total Players">
            {night.players?.length ?? 0}
          </DrawerField>
          <hr />
          <DrawerField label="Game Sets">
            {totalSets}
          </DrawerField>
          <DrawerField label="Total Games">
            {totalGames}
          </DrawerField>
          {night.player_fee && (
            <>
              <hr />
              <DrawerField label="Player Fee">
                ${formatNumber(night.player_fee)}
              </DrawerField>
              <DrawerField label="Collected">
                ${formatNumber(totalCollected)}
              </DrawerField>
            </>
          )}
          {newPlayers.length > 0 && (
            <>
              <hr />
              <DrawerField label="New Players">
                <div className="flex flex-col gap-2">
                  {newPlayers.map((player) => (
                    <PlayerDot key={player.id} player={player} inline />
                  ))}
                </div>
              </DrawerField>
            </>
          )}
        </DrawerCard>
      )}
    </RoutedDrawer>
  );
};

export default injectModal(
  SessionDrawerController.scope(
    ApplicationView(SessionDrawer)
  )
);
