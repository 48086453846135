import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useFloating } from "@floating-ui/react";
import {
  Button,
  DropdownMenu,
  DropdownMenuItem,
  GroupDot,
  ListSkeleton,
  PanelController,
  Toggle,
} from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import { isNil } from "@mixitone/util";
import PlusIcon from "components/icons/PlusIcon";
import { useCallback, useEffect, useMemo, useRef } from "react";
import DeleteAlert from "./DeleteAlert";
import GroupListController from "./GroupListController";
import GroupRow from "./GroupRow";

const GroupMenu = ApplicationView(
  ({ dropRef, style }: { dropRef: (el: HTMLDivElement) => void; style: React.CSSProperties }) => {
    const controller = GroupListController.use();
    const { groups, menuGroupId } = controller.state;
    const menuGroup = menuGroupId ? groups.findById(menuGroupId) : null;

    const handleGroupClose = useCallback(() => {
      controller.setState({ menuGroupId: null });
    }, []);

    const handleToggleGroupOnly = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        controller.actionToggleExcludeGroup(menuGroupId!, event.target.value, !event.target.checked);
      },
      [menuGroupId],
    );

    return (
      <DropdownMenu dropRef={dropRef} open={!isNil(menuGroupId)} onClose={handleGroupClose} style={style}>
        <DropdownMenuItem value={null}>Plays groups</DropdownMenuItem>
        {groups.map((group) => (
          <DropdownMenuItem
            key={`group-dropdown-item-${group.uniqueId}`}
            value={group.uniqueId}
            className="flex items-center justify-between gap-2"
          >
            <div className="flex items-center gap-2">
              <GroupDot group={group} size={20} />
              {group.name}
            </div>
            <Toggle
              value={group.id!}
              checked={menuGroupId === group.id! || !menuGroup?.excludesGroup(group.id!)}
              onChange={handleToggleGroupOnly}
              disabled={menuGroupId === group.id}
            />
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    );
  },
);

const GroupList = ApplicationView(() => {
  const controller = GroupListController.use();
  const { loading, club, groups, updating, adding } = controller.state;
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const panelController = PanelController.useOptional();
  const handleAddGroup: React.MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.preventDefault();
    controller.actionAddGroup();
  }, []);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;
    controller.actionSwapGroups(active.id as string, over.id as string);
  };

  const { refs, floatingStyles } = useFloating();
  const rowRefs = useRef(new Map<string, HTMLElement | null>());

  const handleGroupClick = useCallback((groupId: string) => {
    const row = rowRefs.current.get(groupId);
    if (row) {
      refs.setReference(row);
      controller.setState({ menuGroupId: groupId });
    }
  }, []);

  const addButton = useMemo(
    () =>
      club.hasManagePermission() && (
        <Button
          onClick={handleAddGroup}
          className="whitespace-nowrap text-xs font-normal"
          spinner={adding}
          disabled={adding}
        >
          <PlusIcon className="mr-1 w-[12px]" />
          Add group
        </Button>
      ),
    [adding, handleAddGroup],
  );

  useEffect(() => {
    if (panelController) {
      panelController.actionSetButtons(addButton);
      return () => {
        panelController.actionSetButtons(null);
      };
    }
  }, [adding, handleAddGroup, panelController]);

  if (loading) {
    return <ListSkeleton itemCount={7} />;
  }

  const items = groups.map((group) => group.uniqueId);

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <fieldset>
          {!panelController && <div className="flex w-full justify-end">{addButton}</div>}
          <table className="w-full">
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr className="text-xs uppercase text-gray-500">
                <th className="text-left" colSpan={2}>
                  Name
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
                disabled={!club.hasManagePermission() || updating}
              >
                {groups.map((group) => (
                  <GroupRow
                    key={`group-row-${group.uniqueId}`}
                    group={group}
                    groupRef={(el) => rowRefs.current.set(group.id!, el)}
                    onGroupClick={handleGroupClick}
                  />
                ))}
              </SortableContext>
            </tbody>
          </table>
          <GroupMenu dropRef={refs.setFloating} style={floatingStyles} />
        </fieldset>
      </DndContext>
      <DeleteAlert />
    </>
  );
});

export default GroupListController.scope(GroupList);
