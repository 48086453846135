import { Button, LabelledField, Input, bindController } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import { SettingsController } from "./SettingsController";

import clsx from "clsx";
import AccountUser from "@mixitone/models/AccountUser";

const SessionSettings: React.FC = () => {
  const controller = SettingsController.use();
  const { updating, courts } = controller.state;
  const bind = bindController(controller);

  return (
    <fieldset disabled={updating}>
      <div className="mb-4">
        <label htmlFor="courts" className="block text-gray-700">
          Number of courts
        </label>
        <input
          type="number"
          id="courts"
          value={courts}
          onChange={(e) => (controller.state.courts = Number(e.target.value))}
          min={1}
          className="block w-full p-2 mt-1 border border-gray-300 rounded"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="player_fee" className="block text-gray-700">
          Player fee
        </label>

        <Input type="number" prefix="$" {...bind("player_fee")} max={999.99} min={0.0} step={1.0} />
      </div>

      <LabelledField label="Warm up time" htmlFor="warm_up_time">
        <Input type="number" suffix="minutes" {...bind("warm_up_time")} max={60} min={1} step={1} />
      </LabelledField>

      <div className="mb-4">
        <label htmlFor="game_set_time" className="block text-gray-700">
          Timer
        </label>

        <Input type="number" suffix="minutes" {...bind("game_set_time")} max={60} min={1} step={1} />
      </div>

      {AccountUser.current && controller.night.game_sets.loaded && (
        <div
          className={clsx("mt-8 flex items-center justify-between rounded border p-3 text-sm", {
            "border-red-200 bg-red-50": controller.canCancel,
            "border-gray-300 bg-gray-100 opacity-50": !controller.canCancel,
          })}
        >
          <span>Oops, I didn't want to start this session now</span>
          <Button
            kind="danger"
            disabled={!controller.canCancel}
            className="text-xs"
            onClick={(event) => {
              event.preventDefault();
              controller.actionCancel();
            }}
          >
            Cancel session
          </Button>
        </div>
      )}
    </fieldset>
  );
};

export default ApplicationView(SessionSettings);
