import { useMemo } from "react";

interface Options {
  session?: boolean;
}

export class LocalStorageState<T extends unknown> {
  constructor(
    public readonly name: string,
    public readonly initialValue?: T,
    private readonly options?: Options,
  ) {}

  get() {
    const initialFromLocalStorage = this.storage.getItem(this.name);

    if (!initialFromLocalStorage && this.initialValue) {
      this.set(this.initialValue);
      return this.initialValue;
    }

    return initialFromLocalStorage ? JSON.parse(initialFromLocalStorage) : undefined;
  }

  set(value: T) {
    this.storage.setItem(this.name, JSON.stringify(value));
  }

  unset() {
    this.storage.removeItem(this.name);
  }

  private get storage() {
    return this.options?.session ? sessionStorage : localStorage;
  }
}

export const useLocalStorageState = <T extends unknown>(name: string, initialValue?: T) => {
  const state = useMemo(() => new LocalStorageState<T>(name, initialValue), [name, initialValue]);

  const setter = (value: T) => {
    state.set(value);
  };

  return [state.get(), setter] as const;
};
