import React from "react";
import { Params, useParams } from "react-router-dom";
import { useModalParams } from "router";

type InjectedProps = {
  params: Readonly<Params<string>>;
};

export function injectParams<P extends InjectedProps>(
  Component: React.ComponentType<P>,
): React.FC<Omit<P, "params">> {
  return (props: Omit<P, "params">) => {
    const params = useParams();
    return <Component {...(props as P)} params={params} />;
  };
}

export function injectModal(Component: React.ComponentType<any>): React.FC {
  return () => {
    const params = useModalParams();
    return <Component {...params} />;
  };
}
