import { Modal } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import RouteController from "controllers/RouteController";

type Props = Omit<React.ComponentProps<typeof Modal>, "onClose">;

const AppModal: React.FC<Props> = (props) => {
  const controller = RouteController.use();

  return (
    <Modal
      {...props}
      onClose={() => {
        controller.actionCloseModal();
      }}
    />
  );
};

export default ApplicationView(AppModal);
