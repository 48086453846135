import { actions } from "@actions/actions";
import tauri from "./tauri";

const reporting = actions.reporting;

export async function clubPlayerLastPlayed(
  ...args: Parameters<typeof reporting.clubPlayerLastPlayed>
): Promise<ReturnType<typeof reporting.clubPlayerLastPlayed>> {
  if (tauri.enabled) {
    const sql = `
        SELECT 
            p.club_player_id,
            COUNT(DISTINCT n.id) as nights_played,
            MAX(n.date) as date_of_last_night_played
        FROM 
            players p
            JOIN nights n ON p.night_id = n.id
        GROUP BY 
            p.club_player_id;
    `;
    return {
      clubPlayersLastPlayed: await tauri.invoke("plugin:mixitonedb|execute", { query: sql, values: [] }),
    };
  } else {
    return reporting.clubPlayerLastPlayed(...args);
  }
}

export async function playersPerNight(
  ...args: Parameters<typeof reporting.playersPerNight>
): Promise<ReturnType<typeof reporting.playersPerNight>> {
  if (tauri.enabled) {
    const params = args[0];
    const { clubId, startDate, endDate } = params;

    const sql = `
        WITH previous_players AS (
          SELECT DISTINCT
            p.club_player_id,
            MIN(n.date) as first_appearance_date
          FROM
            nights n
            JOIN players p ON n.id = p.night_id
          WHERE
            n.is_deleted = 'false'
            AND p.is_deleted = 'false'
            AND n.club_id = ?
          GROUP BY
            p.club_player_id
        )
        SELECT
            n.id,
            n.date,
            COUNT(DISTINCT p.id) as players_per_night,
            COUNT(DISTINCT CASE 
                WHEN pp.first_appearance_date = n.date THEN p.club_player_id 
                ELSE NULL 
            END) as new_players_count
        FROM
            nights n
            JOIN players p ON n.id = p.night_id
            LEFT JOIN previous_players pp ON p.club_player_id = pp.club_player_id
        WHERE
            n.is_deleted = 'false'
            AND p.is_deleted = 'false'
            AND n.club_id = ?
            AND date(n.date) >= date(?)
            AND date(n.date) <= date(?)
        GROUP BY
            n.id
        ORDER BY
            n.date;
    `;

    return {
      playersPerNight: await tauri.invoke("plugin:mixitonedb|execute", {
        query: sql,
        values: [clubId, clubId, startDate, endDate],
      }),
    };
  } else {
    return reporting.playersPerNight(...args);
  }
}

export async function sessionNewPlayers(
  ...args: Parameters<typeof reporting.sessionNewPlayers>
): Promise<ReturnType<typeof reporting.sessionNewPlayers>> {
  if (tauri.enabled) {
    const params = args[0];
    const { sessionId } = params;

    const sql = `
        WITH previous_players AS (
          SELECT DISTINCT
            p.club_player_id,
            MIN(n.date) as first_appearance_date
          FROM
            nights n
            JOIN players p ON n.id = p.night_id
          WHERE
            n.is_deleted = 'false'
            AND p.is_deleted = 'false'
          GROUP BY
            p.club_player_id
        )
        SELECT DISTINCT
          p.club_player_id
        FROM
          nights n
          JOIN players p ON n.id = p.night_id
          JOIN previous_players pp ON p.club_player_id = pp.club_player_id
        WHERE
          n.is_deleted = 'false'
          AND p.is_deleted = 'false'
          AND n.id = ?
          AND pp.first_appearance_date = n.date;
    `;

    const results = await tauri.invoke<Array<{ club_player_id: string }>>("plugin:mixitonedb|execute", {
      query: sql,
      values: [sessionId],
    });

    return {
      clubPlayerIds: results.map((r) => r.club_player_id),
    };
  } else {
    return reporting.sessionNewPlayers(...args);
  }
}
