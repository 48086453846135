import { ApplicationController, observable } from "@mixitone/mvc";

const MESSAGE_TIMEOUT = 4000;

export type MessageType = "error" | "success" | "info" | "warning";
export interface AppMessage {
  body: React.ReactNode;
  type: MessageType;
  fading?: boolean;
}

interface State {
  messages: AppMessage[];
}

const messagesStore = observable({
  messages: [] as AppMessage[],
});
// @ts-ignore
messagesStore.clone = function () {
  // @ts-ignore
  return this;
}.bind(messagesStore);

export default class MessagesController extends ApplicationController<State> {
  get initialState(): State {
    return messagesStore;
  }

  static addMessage(body: React.ReactNode, type: MessageType) {
    messagesStore.messages.push({ body, type });
    setTimeout(() => {
      const index = messagesStore.messages.length - 1;
      MessagesController.acknowledgeMessage(index);
    }, MESSAGE_TIMEOUT);
  }

  static clearMessages() {
    messagesStore.messages = [];
  }

  static acknowledgeMessage(index: number) {
    if (!messagesStore.messages[index]) return;
    messagesStore.messages[index].fading = true;
  }

  static deleteMessage(index: number) {
    if (!messagesStore.messages[index]) return;
    messagesStore.messages.splice(index, 1);
  }

  actionAddMessage(body: React.ReactNode, type: MessageType) {
    MessagesController.addMessage(body, type);
  }

  actionClearMessages() {
    MessagesController.clearMessages();
  }

  actionAcknowledgeMessage(index: number) {
    MessagesController.acknowledgeMessage(index);
  }

  actionDeleteMessage(index: number) {
    MessagesController.deleteMessage(index);
  }
}
