import { FunctionsResponse } from "@supabase/functions-js";

export interface User {
  id: string;
  email?: string;
  user_metadata?: Record<string, any>;
  is_anonymous?: boolean;
}

export interface Session {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expires_at?: number;
  token_type: string;
  user: User;
  adapter: string;
}

export type ResponseWithError<T> =
  | {
      error: null | undefined;
      data: T;
    }
  | {
      error: Error;
      data: null | undefined;
    };

export enum AuthEvent {
  "INITIAL_SESSION" = "INITIAL_SESSION",
  "PASSWORD_RECOVERY" = "PASSWORD_RECOVERY",
  "SIGNED_IN" = "SIGNED_IN",
  "SIGNED_OUT" = "SIGNED_OUT",
  "TOKEN_REFRESHED" = "TOKEN_REFRESHED",
  "USER_UPDATED" = "USER_UPDATED",
}

const AuthEvents = Object.values(AuthEvent);
export function isAuthEvent(event: string): event is AuthEvent {
  return AuthEvents.includes(event as AuthEvent);
}

export abstract class SessionAdapter {
  get canSignUp() {
    return false;
  }
  get canSignIn() {
    return false;
  }
  get canSignOut() {
    return false;
  }
  get canResetPassword() {
    return false;
  }

  signOut(): Promise<ResponseWithError<undefined>> {
    throw new Error("signOut not implemented");
  }

  signUp(
    email: string,
    password: string,
    options?: {
      data: {
        name: string;
        inviteToken?: string;
      };
    },
  ): Promise<any> {
    throw new Error("signUp not implemented");
  }

  signInWithPassword(
    email: string,
    password: string,
  ): Promise<ResponseWithError<{ session: Session; user: User }>> {
    throw new Error("signInWithPassword not implemented");
  }

  resetPasswordForEmail(email: string, redirectTo: string): Promise<ResponseWithError<{}>> {
    throw new Error("resetPasswordForEmail not implemented");
  }

  updatePassword(password: string): Promise<ResponseWithError<{}>> {
    throw new Error("updatePassword not implemented");
  }

  updateName(name: string): Promise<ResponseWithError<{}>> {
    throw new Error("updateName not implemented");
  }

  getUser(): Promise<ResponseWithError<{ user: User }>> {
    throw new Error("getUser not implemented");
  }

  abstract getSession(): Promise<Session | null>;

  _listeners: Function[] = [];

  onAuthStateChange(callback: (event: AuthEvent, session: Session | null) => void) {
    this._listeners.push(callback);
    this.getSession().then((session) => {
      callback(AuthEvent.INITIAL_SESSION, session);
    });
  }

  abstract rpc<T>(
    functionName: string,
    args: any,
  ): Promise<
    | {
        error: undefined;
        data: T;
      }
    | { error: Error; data: undefined }
  >;

  abstract edgeFunction(functionName: string, args: any): Promise<FunctionsResponse<any>>;

  abstract initialize(): Promise<void>;

  protected emit(event: AuthEvent, session: Session | null) {
    this._listeners.forEach((listener) => {
      listener(event, session);
    });
  }
}
