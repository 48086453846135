import { Night } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import SessionForm from "components/Forms/SessionForm";
import RouteController from "controllers/RouteController";
import { injectModal } from "lib/injectParams";
import { useCallback } from "react";

interface Props {
  clubId: string;
  recurringSessionId?: string;
}

const NewSession: React.FC<Props> = ({ clubId, recurringSessionId }) => {
  const routeController = RouteController.use();

  const handleSubmit = useCallback((record: Night) => {
    routeController.actionCloseModal({ at: "/t/:token", routeParams: { token: record.id! } });
    return Promise.resolve(void 0);
  }, []);

  return (
    <AppModal title="New session">
      <SessionForm
        attributes={{ club_id: clubId, recurring_session_id: recurringSessionId }}
        onSubmit={handleSubmit}
      />
    </AppModal>
  );
};

export default injectModal(ApplicationView(NewSession));
