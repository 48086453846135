import {
  AllGroup,
  Button,
  DropdownButton,
  DropdownMenuItem,
  GroupDotName,
  NoneGroup,
  Toggle,
} from "@mixitone/components";
import { Group } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import { isNil, isPresent } from "@mixitone/util";
import { useCallback } from "react";

interface GroupSelectListProps {
  includeAll?: boolean;
  includeNone?: boolean;
  clubGroups: Group[];
  onSelectGroup: (group: Group | undefined) => void;
}

interface SingleSelectPropsBase {
  includeAll?: boolean;
  includeNone?: boolean;
  kind?: React.ComponentProps<typeof Button>["kind"];
  className?: string;
  name?: string;
  value: Group | undefined;
}

type SingleSelectPropsNoList = SingleSelectPropsBase & GroupSelectListProps;
type SingleSelectProps = SingleSelectPropsNoList

type MultiSelectProps = Omit<
  SingleSelectPropsNoList,
  "value" | "onSelectGroup" | "includeAll" | "includeNone"
> & {
  value: Group[];
  onSelectGroup: (group: Group) => void;
  onUnselectGroup: (group: Group) => void;
};

const GroupSelectListComponent: React.FC<GroupSelectListProps> = ({
  includeAll,
  includeNone,
  onSelectGroup,
  clubGroups,
}) => {
  if (includeAll && includeNone) {
    throw new Error("Group list cannot have both includeAll and includeNone");
  }

  return (
    <>
      {(includeAll || includeNone) && (
        <DropdownMenuItem closeOnClick onClick={() => onSelectGroup(undefined)}>
          <>
            {includeAll && <AllGroup />}
            {includeNone && <NoneGroup />}
          </>
        </DropdownMenuItem>
      )}
      {clubGroups.map((group) => (
        <DropdownMenuItem
          key={`group-dropdown-${group.id}`}
          closeOnClick
          onClick={() => onSelectGroup(group)}
        >
          <GroupDotName group={group} />
        </DropdownMenuItem>
      ))}
    </>
  );
};

const GroupSelectComponent: React.FC<SingleSelectProps> = (props) => {
  const { kind, className, name, value, includeAll, includeNone } = props;

  let buttonValue;

  if (isPresent(value) && value instanceof Group) {
    buttonValue = <GroupDotName group={value} />;
  } else {
    buttonValue = null;
  }

  if (isNil(value) && includeAll) {
    buttonValue = <AllGroup />;
  }
  if (isNil(value) && includeNone) {
    buttonValue = <NoneGroup />;
  }

  return (
    <DropdownButton
      buttonProps={{ kind: kind || "select", className, type: "button", name }}
      button={<>{buttonValue}</>}
    >
      {() => <GroupSelectListComponent {...props} />}
    </DropdownButton>
  );
};

const GroupMultiSelectComponent: React.FC<MultiSelectProps> = ({
  clubGroups,
  onSelectGroup,
  onUnselectGroup,
  value,
  className,
  kind,
  name,
}) => {
  const handleItemClick = useCallback(
    (group: Group) => {
      if (value.includes(group)) {
        onUnselectGroup(group);
      } else {
        onSelectGroup(group);
      }
    },
    [value],
  );

  let buttonValue;

  if (Array.isArray(value) && value.length > 0) {
    buttonValue = value.map((v) => v.name).join(", ");
  } else {
    buttonValue = null;
  }

  if (isNil(value) || value.length === 0) {
    buttonValue = <NoneGroup />;
  }

  return (
    <DropdownButton
      buttonProps={{ kind: kind || "select", className, type: "button", name }}
      button={<>{buttonValue}</>}
    >
      {clubGroups.map((group) => (
        <DropdownMenuItem
          key={`group-dropdown-${group.id}`}
          onClick={() => handleItemClick(group)}
          className="flex items-center gap-1"
        >
          <Toggle small checked={value.includes(group)} onChange={() => {}} />
          <GroupDotName group={group} />
        </DropdownMenuItem>
      ))}
    </DropdownButton>
  );
};

export const GroupSelect = ApplicationView(GroupSelectComponent);
export const GroupMultiSelect = ApplicationView(GroupMultiSelectComponent);
export const GroupSelectList = ApplicationView(GroupSelectListComponent);
