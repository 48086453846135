const FormField: React.FC<{ label: React.ReactNode; htmlFor: string; children: React.ReactNode }> = ({
  label,
  htmlFor,
  children,
}) => (
  <div className="mb-4">
    <label htmlFor={htmlFor} className="block font-bold text-white text-gray-700">
      {label}
    </label>
    {children}
  </div>
);

export default FormField;
