import { actions } from "@actions/actions";
import { Account, AccountUser, Club, ClubPermission } from "@mixitone/models";
import { List } from "@mixitone/oom";
import MixitoneController from "controllers/MixitoneController";
import SessionController from "controllers/SessionController";

interface State {
  creating: boolean;
  errors: Record<string, string>;
  clubId?: string;
  email: string;
  clubs: List<Club>;
  indexedPermissions: Record<string, ClubPermission>;
}

interface Props {
  clubId: string;
}

export class InviteAccountUserController extends MixitoneController<State, Props> {
  get initialState() {
    return { creating: false, errors: {}, email: "", clubs: new List<Club>(), indexedPermissions: {} };
  }

  override async initialize(props: Props) {
    if (!Account.current) return;

    this.state.clubId = props.clubId;

    await Account.current.clubs.reload();
    const clubs = Account.current.clubs;
    this.state.clubs = new List(...clubs);
    this.state.indexedPermissions = {};

    for (const club of clubs) {
      const permission = new ClubPermission({
        account_id: Account.current.id,
        club_id: club.id,
      });

      this.state.indexedPermissions[club.uniqueId] = permission;
    }
  }

  async actionSubmit() {
    this.setState({ creating: true });

    const userId = this.get(SessionController).user?.id;
    if (!userId) {
      throw new Error("No user found");
    }
    const accountUser = await AccountUser.query().eq("user_id", userId).first();
    if (!accountUser) {
      throw new Error("No account user found");
    }

    try {
      // Copy permissions structure
      const permissions: Record<
        string,
        Pick<ClubPermission, "view_club" | "admin_club" | "manage_club" | "manage_sessions">
      > = {};

      Object.keys(this.state.indexedPermissions).forEach((clubId) => {
        const permission = this.state.indexedPermissions[clubId];
        permissions[clubId] = {
          view_club: permission.view_club,
          admin_club: permission.admin_club,
          manage_club: permission.manage_club,
          manage_sessions: permission.manage_sessions,
        };
      });

      // Invoke edge function
      const response = await actions.inviteAdmin.inviteAdmin({
        email: this.state.email,
        permissions,
      });

      if (response.data !== "ok") {
        throw new Error("Error sending invite");
      }

      this.actionAddMessage("Invitation sent", "success");
      this.actionBack();
    } catch (err) {
      const { message } = err as Error;
      if (message.includes("duplicate")) {
        this.setState({ errors: { email: "Email already invited" } });
      } else {
        this.setState({ errors: { email: "Error sending invite" } });
      }

      this.setState({ creating: false });
    }
  }
}
