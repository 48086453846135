import { Model, ModelAttributes, model, modelConfig, reference } from "@mixitone/oom";
import { Loadable } from "@mixitone/oom/reference";
import { Database } from "./database.types";
import Account from "./Account";
import { keyBy } from "@mixitone/util";
import ClubPermission from "./ClubPermission";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    name: {
      type: "string",
    },
    role: {
      type: "enum",
      enumType: "Admin" as Database["public"]["Enums"]["user_role"],
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
  },
  {},
  {
    account: reference<any>(() => Account as any, "account_id"),
  },
);

interface AccountUser extends ModelAttributes<typeof config> {
  account: Loadable<Account>;
  _clubPermissions?: Record<string, ClubPermission>;
}

@model("account_users", config)
class AccountUser extends Model<typeof config> {
  static current: AccountUser | null = null;

  static byId(uid: string) {
    return AccountUser.query().find(uid);
  }

  static forUser(uid: string) {
    return AccountUser.query().eq("user_id", uid).preload("account").limit(5).all();
  }

  override canDestroy() {
    return this.role === "Manager";
  }

  async loadClubPermissions() {
    const clubPermissions = await ClubPermission.query().eq("user_id", this.user_id).all();
    this._clubPermissions = keyBy(clubPermissions, "club_id");
  }
  clubPermissionFor(clubId: string) {
    if (!this._clubPermissions) {
      throw new Error("Club permissions not loaded");
    }
    if (!this._clubPermissions[clubId]) {
      this._clubPermissions[clubId] = new ClubPermission({ user_id: this.id, club_id: clubId });
      if (this.role === "Admin") {
        ClubPermission.PermissionKeys.forEach((key) => {
          this._clubPermissions![clubId].writeAttribute(key, true);
        });
      }
    }
    return this._clubPermissions[clubId];
  }

  isAdmin() {
    return this.role === "Admin";
  }
}

export default AccountUser;
