import React, { useRef, useEffect, useState } from "react";
import {
  useFloating,
  shift,
  FloatingArrow,
  arrow,
  offset,
  autoUpdate,
  hide,
  Placement,
} from "@floating-ui/react";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import InfoIcon from "../icons/info.svg";
import { createPortal } from "react-dom";

interface Props {
  targetRef?: React.RefObject<HTMLElement | null>;
  children: React.ReactNode;
  position?: Placement;
  icon?: boolean;
  className?: string;
  hover?: boolean;
}

const Tooltip: React.FC<Props> = ({ targetRef, children, position = "top", className, icon, hover }) => {
  const arrowRef = useRef(null);
  const [realTargetRef, setRealTargetRef] = useState(targetRef?.current || null);
  const [overIcon, setOverIcon] = React.useState(false);
  const [open, setOpen] = useState(!hover);

  const { x, y, strategy, refs, context, update, middlewareData } = useFloating({
    open,
    placement: position,
    strategy: "fixed",
    middleware: [
      offset(10),
      shift({
        padding: 16,
      }),
      arrow({ element: arrowRef }),
      hide({ strategy: "escaped" }),
    ],
  });

  useEffect(() => {
    if (targetRef?.current) {
      setRealTargetRef(targetRef.current);
    }
  }, [targetRef]);

  useEffect(() => {
    if (realTargetRef) {
      refs.setReference(realTargetRef);
      if (!refs.floating.current) return;

      const cleanup = autoUpdate(realTargetRef, refs.floating.current, update);
      return cleanup; // Clean up the autoUpdate listener when the component unmounts
    }
  }, [realTargetRef, refs, update]);

  useEffect(() => {
    if (!hover) return;
    if (!realTargetRef) return;

    const handleMouseEnter = () => {
      setOpen(true);
    };
    const handleMouseLeave = () => {
      setOpen(false);
    };

    realTargetRef.addEventListener("mouseenter", handleMouseEnter);
    realTargetRef.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      realTargetRef.removeEventListener("mouseenter", handleMouseEnter);
      realTargetRef.removeEventListener("mouseleave", handleMouseLeave);
    };
  });

  const style: React.CSSProperties = {
    position: strategy,
    top: y ?? 0,
    left: x ?? 0,
    width: "max-content",
  };
  if (!open || middlewareData.hide?.escaped || (icon && !overIcon)) {
    style.opacity = 0;
    style.pointerEvents = "none";
  }

  const tooltip = (
    <div
      ref={refs.setFloating}
      className={twMerge(
        clsx(
          "z-100 rounded bg-slate-800 px-3 py-2 text-sm text-white shadow-md transition-opacity duration-200",
        ),
        className,
      )}
      style={style}
      role="tooltip"
    >
      {children}
      {/* @ts-ignore - Mixing v18 and v19 react types */}
      <FloatingArrow ref={arrowRef} context={context} fill="black" />
    </div>
  );

  if (icon) {
    return (
      <>
        <span
          ref={(el) => setRealTargetRef(el)}
          onMouseOver={() => setOverIcon(true)}
          onMouseOut={() => setOverIcon(false)}
        >
          <InfoIcon className="w-[14px] stroke-blue-600" />
        </span>
        {tooltip}
      </>
    );
  }

  return createPortal(tooltip, document.body);
};

export { Tooltip };
