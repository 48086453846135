/**
 * Formatting utilities
 */

const numberFormatter = new Intl.NumberFormat();

export function formatNumber(number: number) {
  return numberFormatter.format(number);
}

export function cn(...inputs: Array<string | undefined | null | false | 0>) {
  return inputs.filter(Boolean).join(" ");
}
