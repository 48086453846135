/**
 * String manipulation utilities
 */

import { pick } from "./object";

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function camelToSnake(str: string): string {
  return str.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
}

type RemoveUnderscoreFirstLetter<S extends string> = S extends `${infer FirstLetter}${infer U}`
  ? `${FirstLetter extends "_" ? U : `${FirstLetter}${U}`}`
  : S;

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${RemoveUnderscoreFirstLetter<Lowercase<T>>}${CamelToSnakeCase<U>}`
  : S;

type KeysToSnakeCase<T extends object> = {
  [K in keyof T as CamelToSnakeCase<K & string>]: T[K];
};

function toSnakeCase<T extends object>(obj: T) {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [camelToSnake(key)]: (obj as any)[key],
    }),
    {},
  ) as KeysToSnakeCase<T>;
}

export function pickSnake<T extends object, K extends keyof T>(obj: T, keys: K[]) {
  return toSnakeCase(pick(obj, keys));
}

export function t(key: string, ...args: string[]): string {
  return args
    .flatMap((arg) => arg.split(" "))
    .map((arg) => [key, arg].join(":"))
    .join(" ");
}

export function tryJsonParse<T>(json: string): T | null {
  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
}
