import { Fragment, Suspense } from "react";
import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom";
import type { ActionFunction, RouteObject, LoaderFunction } from "react-router-dom";
import { generateModalRoutes, generatePreservedRoutes, generateRegularRoutes } from "../core";
import { ModalContextProvider } from "./context";

type Element = () => JSX.Element;
type Module = {
  default: Element;
  Loader?: LoaderFunction;
  Action?: ActionFunction;
  Catch?: Element;
  Pending?: Element;
};
type ModalState = Array<{ path: string; params: Record<string, any> }>;

const PRESERVED = import.meta.glob<Module>("/src/pages/(_app|404).{jsx,tsx}", { eager: true });
const MODALS = import.meta.glob<Pick<Module, "default">>("/src/pages/**/[+]*.{jsx,tsx}", { eager: true });
const ROUTES = import.meta.glob<Module>([
  "/src/pages/**/[\\w[-]*.{jsx,tsx,mdx}",
  "!/src/pages/**/(_!(layout)*(/*)?|_app|404)*",
]);

const preservedRoutes = generatePreservedRoutes<Omit<Module, "Action">>(PRESERVED);
const modalRoutes = generateModalRoutes<Element>(MODALS);

const regularRoutes = generateRegularRoutes<RouteObject, () => Promise<Partial<Module>>>(
  ROUTES,
  (module, key) => {
    const index = /index\.(jsx|tsx|mdx)$/.test(key) && !key.includes("pages/index") ? { index: true } : {};

    return {
      ...index,
      lazy: async () => {
        const Default = (await module())?.default || Fragment;
        const Pending = (await module())?.Pending;
        const Page = () =>
          Pending ? <Suspense fallback={<Pending />} children={<Default />} /> : <Default />;

        return {
          Component: Page,
          ErrorBoundary: (await module())?.Catch,
          loader: (await module())?.Loader,
          action: (await module())?.Action,
        };
      },
    };
  },
);

const _app = preservedRoutes?.["_app"];
const _404 = preservedRoutes?.["404"];

const Default = _app?.default || Outlet;

const ModalWrapper = ({ Modal, params }: { Modal: Element; params: Record<string, any> }) => (
  <ModalContextProvider value={{ params }}>
    <Modal />
  </ModalContextProvider>
);

const Modals_ = () => {
  const location = useLocation();
  const modals: ModalState = location.state?.modals ?? [];
  const Modals = modals.map(
    (modal) => [modal.path, modalRoutes[modal.path] || Fragment, modal.params] as [string, Element, Record<string, any>],
  );
  return (
    <>
      {Modals.map(([key, Modal, params], idx) => (
        <ModalWrapper key={`${key}-${idx}`} Modal={Modal} params={params} />
      ))}
    </>
  );
};

const Layout = () => (
  <>
    <Default />
    <Modals_ />
  </>
);

const App = () =>
  _app?.Pending ? <Suspense fallback={<_app.Pending />} children={<Layout />} /> : <Layout />;

const app = { Component: _app?.default ? App : Layout, ErrorBoundary: _app?.Catch, loader: _app?.Loader };
const fallback = { path: "*", Component: _404?.default || Fragment };

export const routes: RouteObject[] = [{ ...app, children: [...regularRoutes, fallback] }];

let router: ReturnType<typeof createBrowserRouter>;
const createRouter = () => ((router ??= createBrowserRouter(routes)), router);
export const Routes = () => <RouterProvider router={createRouter()} />;
