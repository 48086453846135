import { Button, ButtonKind, Spinner, buttonClasses } from "@mixitone/components";
import { omit } from "@mixitone/util";
import React from "react";
import { Link as RouterLink } from "router";
import { twMerge } from "tailwind-merge";

type RouterLinkProps = React.ComponentProps<typeof RouterLink>;

type LinkProps = RouterLinkProps & {
  children: React.ReactNode;
  kind?: ButtonKind;
  spinner?: boolean;
  className?: string;
  groupPosition?: React.ComponentProps<typeof Button>["groupPosition"];
  ref?: React.Ref<HTMLAnchorElement>;
};

const RoutedLink: React.FC<LinkProps> = ({ ref, ...props }) => {
  const classes = buttonClasses(props.kind || "primary", twMerge(props.className, ""));

  return (
    // @ts-ignore this error is odd, as the props come from RouterLink but it
    // says 'to' is incompatible
    <RouterLink
      {...omit(props, ["kind", "spinner", "groupPosition"])}
      onClick={props.onClick}
      className={classes}
      ref={ref}
    >
      <span className="inline-flex items-center gap-1">
        {props.spinner && <Spinner size={16} />}
        {!props.spinner && props.children}
      </span>
    </RouterLink>
  );
};

export { RoutedLink };
