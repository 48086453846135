import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import RecurringSessionForm from "components/Forms/RecurringSessionForm";
import { injectModal } from "lib/injectParams";

interface Props {
  clubId: string;
  eventId: string;
}

const EditEvent: React.FC<Props> = ({ eventId, clubId }) => {
  return (
    <AppModal title="Edit time">
      <RecurringSessionForm attributes={{ club_id: clubId || "" }} id={eventId} />
    </AppModal>
  );
};

export default injectModal(ApplicationView(EditEvent));
