import { ApplicationView, useController } from "@mixitone/mvc";
import { PropsWithChildren } from "react";
import { FormController } from "./FormController";
import DeleteModal from "./DeleteModal";

const Form: React.FC<{ name: string } & PropsWithChildren> = ({ name, children }) => {
  const controller = useController(FormController);

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    controller.actionSubmit();
  };

  return (
    <>
      <form onSubmit={handleOnSubmit}>{children}</form>
      <DeleteModal name={name} />
    </>
  );
};

export default ApplicationView(Form);
