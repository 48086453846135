import { Spinner } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import Tauri from "Tauri";
import clsx from "clsx";
import RouteController from "controllers/RouteController";
import SessionController from "controllers/SessionController";
import tauri from "lib/tauri";
import { Outlet, useLocation } from "react-router-dom";
import { useModals, useNavigate, useParams } from "../router";

const Session = ApplicationView(() => {
  const controller = SessionController.use();
  const { loginState } = controller.state;
  console.log("loginState", loginState);

  if (tauri.enabled) {
    return <Tauri />;
  }

  if (loginState === "unknown") {
    return (
      <div className={clsx("h-screen bg-brand-600", { "sm:p-10": !tauri.enabled })}>
        <div
          className={clsx(
            "flex h-full w-full items-center justify-center bg-slate-800 bg-gradient-to-r from-slate-700 to-slate-800 sm:flex-col sm:bg-gradient-to-b",
            { "sm:rounded-lg": !tauri.enabled },
          )}
        >
          <Spinner size={128} className="fill-brand-600" />
        </div>
      </div>
    );
  }

  return <Outlet />;
});

const RoutedOuter = () => {
  const controller = RouteController.use();
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore
  const params = useParams(location.pathname);
  const modals = useModals();
  controller.actionSetup({ navigate, location, params, modals });

  if (!controller.state.ready) {
    return null;
  }
  return <Session />;
};

export default ApplicationView(RoutedOuter);
