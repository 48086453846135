import { ApplicationView } from "@mixitone/mvc";
import SessionController from "controllers/SessionController";
import RouteController from "controllers/RouteController";
import { Routes } from "@mixitone/router";

const Session = () => {
  return (
    <>
      <Routes />
    </>
  );
};

export default RouteController.scope(SessionController.scope(ApplicationView(Session)));
