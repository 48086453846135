import Account from "@mixitone/models/Account";
import AccountUser from "@mixitone/models/AccountUser";
import Club from "@mixitone/models/Club";
import ClubPermission from "@mixitone/models/ClubPermission";
import { List } from "@mixitone/oom";
import { withStateToggle, keyBy } from "@mixitone/util";
import MessagesController from "controllers/MessagesController";
import MixitoneController from "controllers/MixitoneController";

interface Props {
  userId: string;
}

interface State {
  loading: boolean;
  saving: boolean;
  accountUserId: string;
  accountUser: AccountUser;
  clubs: List<Club>;
  permissions: ClubPermission[];
  indexedPermissions: Record<string, ClubPermission>;
}

export class UserPermissionsController extends MixitoneController<State, Props> {
  get initialState(): State {
    return {
      loading: true,
      saving: false,
      accountUserId: "",
      accountUser: new AccountUser({}),
      clubs: new List(),
      permissions: [],
      indexedPermissions: {},
    };
  }

  override async initialize(props: Props) {
    this.state.accountUserId = props.userId;
    await this.loadClubs();
  }

  @withStateToggle("loading")
  async loadClubs() {
    if (!Account.current) return;

    this.state.accountUser = await AccountUser.query().find(this.state.accountUserId);
    const userId = this.state.accountUser.user_id;

    await Account.current.clubs.reload();
    const clubs = Account.current.clubs;
    const permissions = await ClubPermission.query()
      .eq("account_id", Account.current.id)
      .eq("user_id", userId)
      .all();

    this.state.clubs = new List(...clubs);
    this.state.indexedPermissions = {};

    const indexedPermissions = keyBy(permissions, (permission) => permission.club_id);

    for (const club of clubs) {
      const permission =
        indexedPermissions[club.uniqueId] ||
        new ClubPermission({
          account_id: Account.current.id,
          user_id: userId,
          club_id: club.id,
        });

      this.state.indexedPermissions[club.uniqueId] = permission;
    }

    this.state.permissions = clubs.map((club) => this.state.indexedPermissions[club.uniqueId]);
  }

  @withStateToggle("saving")
  async actionSubmit() {
    try {
      await ClubPermission.saveBatch(this.state.permissions);
      MessagesController.addMessage("Permissions saved", "success");
      this.actionCloseModal();
    } catch (e) {
      MessagesController.addMessage("Error saving permissions", "error");
    }
  }
}
