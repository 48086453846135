import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import ClubPlayerForm from "components/Forms/ClubPlayerForm";
import { injectModal } from "lib/injectParams";

interface Props {
  clubId: string;
  id: string;
}

const EditPlayer: React.FC<Props> = ({ clubId, id }) => {
  return (
    <AppModal title="Edit player">
      <ClubPlayerForm attributes={{ club_id: clubId }} id={id}></ClubPlayerForm>
    </AppModal>
  );
};

export default injectModal(ApplicationView(EditPlayer));
