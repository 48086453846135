import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import FillRuleForm from "components/Forms/FillRuleForm";
import { injectModal } from "lib/injectParams";

interface Props {
  clubId: string;
}

const NewRule: React.FC<Props> = ({ clubId }) => {
  return (
    <AppModal title="New rule">
      <FillRuleForm attributes={{ club_id: clubId }} />
    </AppModal>
  );
};

export default injectModal(ApplicationView(NewRule));
