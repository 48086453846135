import { Button, Input, LabelledField, Select, Spinner, bindControllerState } from "@mixitone/components";
import RecurringSession from "@mixitone/models/RecurringSession";
import { ApplicationView } from "@mixitone/mvc";
import Form from "./Form";
import { FormController } from "./FormController";
import Club from "@mixitone/models/Club";

class RecurringSessionFormController extends FormController<
  RecurringSession,
  { club_id: string },
  { club: Club }
> {
  override async onLoad() {
    this.state.club = await Club.query().find(this.state.attributes.club_id);
  }

  override findModel(id: string) {
    return RecurringSession.query().find(id);
  }
  override buildRecord(): RecurringSession {
    return new RecurringSession({
      weekday: 1,
      courts: 5,
      start_time: "18:00",
      end_time: "20:00",
      club_id: this.state.attributes.club_id,
    });
  }

  override saveRecord(record: RecurringSession): Promise<void> {
    record.club_id = this.state.attributes.club_id;
    return record.save();
  }

  override get canDelete(): boolean {
    return this.state.club?.hasManagePermission();
  }

  override get canSave(): boolean {
    return this.state.club?.hasManagePermission();
  }
}

const RecurringSessionForm: React.FC = () => {
  const controller = RecurringSessionFormController.use();
  const { bind } = bindControllerState(controller, "record");
  const { saving, loading } = controller.state;
  const { canDelete, canSave } = controller;

  if (loading) return <Spinner size={64} />;

  return (
    <Form name="event">
      <fieldset disabled={saving || !canSave}>
        <LabelledField label="Weekday" htmlFor="weekday">
          <Select autoFocus required {...bind("weekday", Number)}>
            {RecurringSession.WEEKDAYS.map((weekday, index) => (
              <option key={`recurring-session-option-${index}`} value={index + 1}>
                {weekday}
              </option>
            ))}
          </Select>
        </LabelledField>

        <LabelledField label="Start time" htmlFor="start_time">
          <Input required {...bind("start_time")} type="time" />
        </LabelledField>

        <LabelledField label="End time" htmlFor="end_time">
          <Input required {...bind("end_time")} type="time" />
        </LabelledField>

        <LabelledField label="Courts" htmlFor="courts">
          <Input required {...bind("courts", (value) => Number(value))} type="number" min={1} />
        </LabelledField>

        <LabelledField label="Player fee" htmlFor="player_fee">
          <Input
            {...bind("player_fee", Number)}
            type="number"
            min={0}
            max={999}
            step={1}
            name="player_fee"
            prefix="$"
          />
        </LabelledField>
      </fieldset>
      <div className="flex items-center justify-end gap-2">
        {canDelete && (
          <Button type="button" kind="danger" disabled={saving} onClick={() => controller.actionDelete()}>
            Delete
          </Button>
        )}
        {canSave && (
          <Button type="submit" disabled={saving} spinner={saving}>
            Save
          </Button>
        )}
      </div>
    </Form>
  );
};

export default RecurringSessionFormController.scope(ApplicationView(RecurringSessionForm));
