import { captureException } from "@sentry/react";
import MixitoneController from "controllers/MixitoneController";
import actionLogger from "lib/actionLogger";
import { GetModelAttributes, Model } from "@mixitone/oom";
import { ValidationError } from "@mixitone/oom/validation";
import { withStateToggle } from "@mixitone/util";

interface FormState<T, R> {
  id?: string;
  loading: boolean;
  saving: boolean;
  errors: Record<string, string>;
  record: T;
  newRecord: boolean;
  attributes: R;
  showDeleteModal: boolean;
}

interface FormProps<T extends Model<any>, R extends Partial<GetModelAttributes<T>>> {
  attributes: R;
  id?: string;
  onSubmit?: (record: T) => Promise<void>;
  onDelete?: (record: T) => Promise<void>;
}

@actionLogger
export class FormController<
  FormModel extends Model<any>,
  Attributes extends Partial<GetModelAttributes<FormModel>>,
  State extends {} = {},
  Props extends {} = {},
> extends MixitoneController<
  FormState<FormModel, Attributes> & State,
  FormProps<FormModel, Attributes> & Props
> {
  static override initialState: FormState<any, {}> = {
    loading: true,
    saving: false,
    errors: {},
    record: undefined,
    attributes: {},
    newRecord: true,
    showDeleteModal: false,
  };

  private onSubmit?: (record: FormModel) => Promise<void>;
  private onDelete?: (record: FormModel) => Promise<void>;
  private _props!: FormProps<FormModel, Attributes> & Props;

  get props() {
    return this._props;
  }

  findModel(id: string): Promise<FormModel> {
    throw new Error("Not implemented");
  }
  buildRecord(): FormModel {
    throw new Error("Not implemented");
  }
  saveRecord(record: FormModel): Promise<void> {
    throw new Error("Not implemented");
  }
  async onLoad() {}

  get canDelete() {
    return this.state.record?.canDestroy() && !this.state.newRecord;
  }

  get canSave() {
    return true;
  }

  override async changeProps(newProps: FormProps<FormModel, Attributes> & Props) {
    this._props = newProps;
    this.onSubmit = newProps.onSubmit;
    this.onDelete = newProps.onDelete;

    if (newProps.id !== this.state.id) {
      this.state.loading = true;
    }
    this.state.id = newProps.id;
    this.state.attributes = newProps.attributes;

    if (newProps.id) {
      if (this.state.record && this.state.record.id === newProps.id) {
        this.state.loading = false;
        return;
      }
      const record = await this.findModel(newProps.id);
      this.state.record = record;
      this.state.newRecord = false;
    } else {
      this.state.record = this.buildRecord();
      this.state.newRecord = true;
    }

    await this.onLoad();
    this.state.loading = false;
  }

  @withStateToggle("saving")
  async actionSubmit() {
    if (!this.canSave) return;

    try {
      await this.saveRecord(this.state.record);

      if (this.onSubmit) {
        await this.onSubmit(this.state.record);
      } else {
        this.actionBack();
      }

      // For a new record reset the form after save
      if (this.state.newRecord) {
        this.state.record = this.buildRecord();
      }
    } catch (err) {
      captureException(err);
      this.state.errors = {};

      console.log(err);

      if (err instanceof ValidationError) {
        for (const [key, value] of Object.entries(err.errors)) {
          this.state.errors[key] = value;
        }
      }

      if (err instanceof Error) {
        const { message } = err;
        if (message.includes("null value") && message.includes("violates not-null constraint")) {
          const match = message.match(/column "(.*?)"/);
          if (match && match[1]) {
            this.state.errors[match[1]] = "cannot be blank";
          }
        }
      }
    }
  }

  async actionDelete() {
    this.state.showDeleteModal = true;
  }

  async actionCancelDelete() {
    this.state.showDeleteModal = false;
  }

  @withStateToggle("saving")
  async actionConfirmDelete() {
    try {
      if (this.state.newRecord) throw new Error("Cannot delete a new record");
      await this.state.record.destroy();
      this.afterDestroy();
    } catch (err) {
      console.log(err);
      captureException(err);
    } finally {
      this.state.showDeleteModal = false;
    }
  }

  actionCancel() {
    if (!this.state.newRecord) {
      this.state.record.reload();
    }
    if (this.onSubmit) {
      this.onSubmit(this.state.record);
    } else {
      this.actionBack();
    }
  }

  afterDestroy() {
    if (this.onDelete) {
      this.onDelete(this.state.record);
    } else {
      this.actionBack();
    }
  }
}
