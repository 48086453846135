import { Club, ClubPlayer, Group } from "@mixitone/models";
import ApplicationController, { batch } from "@mixitone/mvc";
import { List, subscribeList } from "@mixitone/oom";
import { withStateToggle } from "@mixitone/util";

interface State {
  loading: boolean;
  updating: boolean;
  adding: boolean;
  club: Club;
  groups: List<Group>;
  menuGroupId: string | null;
  deleteGroup: Group | null;
  defaultGroup: Group | null;
  deleting: boolean;
}

interface Props {
  club: Club;
}
export default class GroupListController extends ApplicationController<State, Props> {
  get initialState(): State {
    return {
      updating: false,
      adding: false,
      loading: true,
      club: new Club({}),
      groups: new List<Group>(),
      menuGroupId: null,
      deleteGroup: null,
      defaultGroup: null,
      deleting: false,
    };
  }

  override async initialize(props: Props) {
    await this.changeProps(props);
  }

  override async changeProps(newProps: Props): Promise<void> {
    this.state.loading = true;
    this.state.club = newProps.club;
    await super.changeProps(newProps);
    await newProps.club.groups.reload();
    this.state.groups = new List(...newProps.club.groups);
    this.addDependency(await subscribeList(this.state.groups, newProps.club.groups.query));
    this.state.loading = false;
  }

  override destroy(): void {
    Group.saveBatch(this.state.groups);
  }

  async saveGroups() {
    this.state.updating = true;
    await batch(async () => {
      this.state.groups.forEach((group, index) => {
        group.index = index;
      });
      await Group.saveBatch(this.state.groups);
      this.state.updating = false;
    });
  }

  async actionAddGroup() {
    this.state.adding = true;

    const index = this.state.groups.length;
    let nameIndex = index;
    let name: string;

    do {
      name = `New group ${++nameIndex}`;
    } while (this.state.groups.some((group) => group.name === name));

    const group = new Group({
      club_id: this.state.club.id,
      name,
      index,
    });

    await group.save();

    this.state.adding = false;
  }

  async actionDeleteGroup(id: string) {
    const group = this.state.groups.findById(id);
    if (!group) return;
    await group.players.loadCount();
    this.state.defaultGroup = this.state.groups.find((g) => g.id !== group.id) ?? null;
    this.state.deleteGroup = group;
  }

  @withStateToggle("deleting")
  async actionConfirmDeleteGroup() {
    try {
      const group = this.state.deleteGroup;

      if (!group) return;
      if (!this.state.defaultGroup) return;

      await ClubPlayer.query().eq("group_id", group.id).updateAll({ group_id: this.state.defaultGroup.id });
      await group.destroy();
    } catch (e) {
    } finally {
      this.state.deleteGroup = null;
    }
  }

  actionCancelDeleteGroup() {
    this.state.deleteGroup = null;
  }

  async actionSwapGroups(id1: string, id2: string) {
    if (id1 === id2) {
      return;
    }

    const { groups } = this.state;
    const oldIndex = groups.findIndexById(id1);
    const newIndex = groups.findIndexById(id2);
    groups.swap(oldIndex, newIndex);
    await this.saveGroups();
  }

  async actionToggleExcludeGroup(groupId: string, excludeGroupId: string, toggle: boolean) {
    const group = this.state.groups.findById(groupId);
    if (!group) return;

    if (toggle) {
      group.addExcludeGroup(excludeGroupId);
    } else {
      group.removeExcludeGroup(excludeGroupId);
    }

    await this.actionUpdateGroup(group);
  }

  async actionUpdateGroup(group: Group) {
    await group.save();
  }
}
