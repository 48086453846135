/**
 * Array manipulation utilities
 */

export function first<T>(array: T[]): T | undefined {
  return array[0];
}

export function sum(numbers: number[]): number {
  return numbers.reduce((total, num) => total + num, 0);
}

export function sample<T>(array: T[]): T | undefined {
  const index = Math.floor(Math.random() * array.length);
  return array[index];
}

/**
 * This function sorts an array into random order
 */
export function shuffle<T>(array: T[]): T[] {
  const result = [...array];
  for (let i = 0; i < result.length; i++) {
    const randomIndex = Math.floor(Math.random() * result.length);
    const temp = result[i];
    result[i] = result[randomIndex];
    result[randomIndex] = temp;
  }
  return result;
}

/**
 * Swap two elements in an array
 */
export function arraySwap<T>(array: T[], index1: number, index2: number): void {
  if (index1 !== index2 && index1 >= 0 && index2 >= 0 && index1 < array.length && index2 < array.length) {
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
  }
}

export function removeMultipleIndices<T>(array: T[], indices: number[]): void {
  indices
    .toSorted((a, b) => b - a)
    .forEach((index) => {
      array.splice(index, 1);
    });
}

/**
 * This function filters an array to unique items based on the return of the
 * given function for each item
 */
export function uniqBy<T>(array: T[], fn: (item: T) => any): T[] {
  const seen = new Set();
  return array.filter((item) => {
    const key = fn(item);
    return !seen.has(key) && seen.add(key);
  });
}
