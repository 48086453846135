import { createContext, useContext } from "react";

type ModalContextType = {
  params: Record<string, any>;
};

const ModalContext = createContext<ModalContextType | null>(null);

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalContext must be used within a modal component");
  }
  return context.params;
};

export const ModalContextProvider = ModalContext.Provider;
