import { GroupDotName, NoneGroup } from "@mixitone/components";
import { Club, FillRule } from "@mixitone/models";
import { RuleType } from "@mixitone/models/FillRule";
import { ApplicationView } from "@mixitone/mvc";
import { isPresent } from "@mixitone/util";
import { FillRuleListController } from "./FillRuleListController";

interface RuleDescriptionProps {
  rule: FillRule;
  club: Club;
}

const ruleTypeToPhrase: Record<RuleType, string> = {
  never: "never plays with",
  only: "only plays with",
  prefers: "prefers to play with",
};

const RuleSubject = ApplicationView(({ rule, club }: RuleDescriptionProps) => {
  switch (rule.subjectType) {
    case "Group":
      const group = club.groups.findById(rule.group_id!);
      if (!group) return null;
      return (
        <span className="flex items-center gap-1">
          Group <GroupDotName group={group} className="inline-flex gap-1 whitespace-nowrap" />
        </span>
      );
    case "Player":
      const player = club.club_players.findById(rule.club_player_id!);
      if (!player) return "Player not found";
      return <span>Player {player.name}</span>;
  }
  return null;
});

const ruleList = ({ rule, club }: RuleDescriptionProps) => {
  if (rule.group_ids) {
    if(rule.group_ids.length === 0) return <NoneGroup />;

    return rule.group_ids
      .map((id) => club.groups.findById(id))
      .filter(isPresent)
      .map((group) => <GroupDotName className="gap-1" key={group.domKey("rule-list")} group={group} />);
  } else if (rule.player_ids) {
    return rule.player_ids
      .map((id) => club.club_players.findById(id))
      .filter(isPresent)
      .map((player) => <span>{player.name}</span>);
  } else {
    return [];
  }
};
const RuleList = ApplicationView(({ rule, club }: RuleDescriptionProps) => {
  return <span className="flex items-center gap-1">{ruleList({ rule, club })}</span>;
});

const FillRuleDescription = ApplicationView(({ rule }: { rule: FillRule }) => {
  const controller = FillRuleListController.use();
  const { club } = controller.state;

  if (!rule.subjectType) return <span>Invalid Rule</span>;

  return (
    <div className="flex items-center gap-1">
      <RuleSubject rule={rule} club={club} /> {ruleTypeToPhrase[rule.rule_type as RuleType]}
      <RuleList rule={rule} club={club} />
    </div>
  );
});

export default FillRuleDescription;
