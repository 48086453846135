import { generatePath, Link as RouterLink, Navigate as RouterNavigate } from 'react-router-dom'
import { To } from './types'

export const components = <Path extends string, Params extends Record<string, any>>() => {
  const Link = <P extends Path>({
    to,
    params,
    ...props
  }: Omit<React.ComponentProps<typeof RouterLink>, 'to'> & {
    to: P | To<P>
    params?: P extends keyof Params ? Params[P] : never
  }) => {
    const path = generatePath(typeof to === 'string' ? to : to.pathname, params || ({} as any))
    return <RouterLink {...props} to={typeof to === 'string' ? path : { ...to, pathname: path }} />
  }

  const Navigate = <P extends Path>({
    to,
    ...props
  }: Omit<React.ComponentProps<typeof RouterNavigate>, 'to'> & {
    to: P
    params?: P extends keyof Params ? Params[P] : never
  }) => {
    const path = generatePath(to, props.params || ({} as any))
    return <RouterNavigate {...props} to={path} />
  }

  return { Link, Navigate }
}
