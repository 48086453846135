import { capitalize, entries, isNil } from "@mixitone/util";
import { observable, raw } from "@nx-js/observer-util";
import dayjs from "dayjs";
import Debug from "debug";
import PQueue from "p-queue";
import { SubscriptionManager, SubscriptionSource } from "./SubscriptionManager";
import { DatabaseAdapter } from "./adapters/DatabaseAdapter";
import { Collection, CollectionDescriptor, Collections, createCollection } from "./collection";
import {
  AnyModelConfig,
  AssignableModelFields,
  Attributes,
  ModelAttributes,
  ModelConfig,
  ModelFields,
} from "./config";
import { oom } from "./index";
import { ModelRegistry } from "./modelRegistry";
import { InsertQuery, Query, UpdateQuery } from "./query";
import { Loadable, ReferenceDescriptor, References, loadableReference } from "./reference";
import requestQueue from "./requestQueue";

let uniqueId = 0;

function isModelConstructor<M extends Model<any>>(value: any): value is ModelConstructor<M> {
  return "tableName" in value;
}

/**
 * @param model The model of the collection. This can take a function returning
 * the model if it needs deferring due to import/load order
 * @param key
 * @param options
 * @returns
 */
export function collection<M extends Model<any>, C extends GetModelConfig<M> = GetModelConfig<M>>(
  model: ModelConstructor<M> | (() => ModelConstructor<M>),
  key: keyof ModelFields<C> & string,
  options: { order?: string } = {},
): CollectionDescriptor<M> {
  let refModel = isModelConstructor(model) ? () => model : model;
  return { model: refModel, key, ...options };
}

export function reference<M extends Model<any>>(
  model: () => ModelConstructor<M>,
  key: string,
): ReferenceDescriptor<M> {
  return { model, key };
}

export type GetModelConfig<M extends Model<any>> =
  M extends Model<infer C> ? (C extends ModelConfig<any, any, any> ? C : never) : never;

export type GetModelAttributes<M extends Model<any>> =
  M extends Model<infer C> ? (C extends AnyModelConfig ? ModelAttributes<C> : never) : never;

export type ModelConstructor<M extends Model<any>> = {
  adapter: DatabaseAdapter;
  tableName: string;
  config: GetModelConfig<M>;
  query(): Query<any, GetModelConfig<M>>;
  hasField(key: string): boolean;
  new (attributes: AssignableModelFields<GetModelConfig<M>>, source?: SubscriptionSource): M;
};

const writeCallbacks = Symbol("writeCallbacks");
const inCallback = Symbol("inCallback");

/**
 * A model class that represents a table in the database.
 *
 * @usage
 *
 * ```ts
 * @model("users", modelConfig({name: {type: "string"}}))
 * class User extends Model<typeof config> {}
 *
 * const user = new User({name: "Noir"});
 * await user.save();
 * console.log(user.id, user.name);
 *
 * const users = await User.query().eq("name", "Noir").all();
 * console.log(users);
 * ```
 */
export class Model<C extends AnyModelConfig> {
  static UPDATE_DELAY = 5000;
  static tableName: string = "";
  static config: AnyModelConfig;

  static get adapter() {
    return oom.config.adapter;
  }

  /**
   * Create a new query for this model
   * @see Query
   */
  static query<M extends Model<any>>(this: ModelConstructor<M>): Query<M, GetModelConfig<M>> {
    return new Query(this, { adapter: this.adapter });
  }

  /**
   * Perform a create to insert a new record into the database.
   *
   * @usage
   * ```ts
   * const user = await User.create({name: "Noir"});
   * ```
   */
  static create<M extends Model<any>>(
    this: ModelConstructor<M>,
    attributes: ModelAttributes<GetModelConfig<M>>,
  ) {
    return new InsertQuery(this, { adapter: this.adapter }).insert(attributes);
  }

  static blank<M extends Model<any>>(this: ModelConstructor<M>): M {
    return new this({});
  }

  /**
   * Check if the model has this field
   */
  static hasField(key: string): boolean {
    return key in this.config.fields;
  }

  /**
   * Given a set of models this will save them all in a single promise.
   */
  static async saveBatch<M extends Model<any>>(batch: M[]): Promise<void> {
    const toCreate = batch.filter((record) => !record.persisted);
    const toUpdate = batch.filter((record) => record.persisted);

    await Promise.all([this.createBatch(toCreate), this.updateBatch(toUpdate)]);
  }
  /**
   * Given a set of models this will update them all in a single promise.
   */
  static async updateBatch<M extends Model<any>>(batch: M[]): Promise<void> {
    if (batch.some((record) => !record.persisted)) {
      throw new Error("Record in batch is not persisted so cannot be updated");
    }

    await Promise.all(batch.map((record) => record.save()));
  }
  /**
   * Given a set of models this will create them all in a single promise.
   */
  static async createBatch<M extends Model<any>>(batch: M[]): Promise<M[]> {
    if (batch.some((record) => record.persisted)) {
      throw new Error("Record in batch is already persisted so cannot be created");
    }
    await Promise.all(batch.map((record) => record.save()));
    return batch;
  }

  /**
   * Test if the given model is an instance of this model
   */
  static is<M extends ModelConstructor<any>>(this: M, model: any): model is InstanceType<M> {
    return model instanceof Model && model.tableName === this.tableName;
  }

  /**
   * Set of fields that have been changed since the last save
   */
  dirtyAttributes: Set<keyof ModelFields<C> & string> = new Set();
  /**
   * The attributes received from the server prior to any transformation
   */
  rawAttributes: Record<string, any> = {};
  private _attributes: Map<keyof ModelFields<C> & string, any> = new Map();
  private _data: Map<string, any> = new Map();
  /**
   * Set of fields that were changed on the last save that we want to block from
   * being updated by outdated server changes
   */
  private _pendingAttributeChanges: Array<Set<keyof ModelFields<C> & string>> = [];
  get pendingAttributes() {
    return this._pendingAttributeChanges.reduce((acc, set) => acc.union(set), new Set());
  }

  clone() {
    return new (this.constructor as ModelConstructor<this>)(this.rawAttributes);
  }

  get attributes() {
    return this._attributes;
  }

  /**
   * A map of extra data that is not part of the model schema. Used for caching,
   * preloads etc
   */
  get data() {
    return this._data;
  }

  private _id: string | undefined = undefined;
  setId(id: string | undefined) {
    if (id !== undefined && typeof id !== "string") {
      throw new Error(`Invalid id: ${id}, id must be a string`);
    }
    this._id = id;
  }
  get id(): string | undefined {
    return this._id;
  }
  /**
   * Generate a unique key for the model that can be used in the DOM
   */
  domKey(...suffixes: Array<string | number>) {
    return `${suffixes.join("-")}-${this.tableName}-${this.uniqueId}`;
  }

  private _uniqueId: number = uniqueId++;
  /**
   * A unique id for the model that is unique across all models in the current
   * process. For saved models this is the primary key id. For unsaved models
   * this is a unique number.
   */
  get uniqueId() {
    if (this.persisted) return this.id!;
    return String(this._uniqueId);
  }

  /**
   * Override this method to provide custom permissions for the model.
   */
  canEdit() {
    return true;
  }
  /**
   * Override this method to provide custom permissions for the model.
   */
  canDestroy() {
    return this.canEdit();
  }

  private debug(...args: any[]) {
    // @ts-ignore
    Debug(`oom:model:${this.constructor.name}`)(...args);
  }

  get fields(): C["fields"] {
    // @ts-ignore
    return this.constructor.config.fields;
  }

  get collections(): Collections {
    // @ts-ignore
    return this.constructor.config.collections;
  }

  get references(): References {
    // @ts-ignore
    return this.constructor.config.references;
  }

  get tableName(): string {
    // @ts-ignore
    return this.constructor.tableName;
  }

  constructor(attributes: AssignableModelFields<C>, source?: SubscriptionSource) {
    if ("attributes" in attributes) {
      attributes = attributes.attributes as any;
    }

    this.defineFields();
    this.defineCollections();
    this.defineReferences();
    this.setAttributes(attributes);

    const model = observable(this);
    if (this.persisted) {
      if (ModelRegistry.instance.has(this)) {
        const registered = ModelRegistry.instance.register(model);
        registered.updateFromSubscription(attributes, source ?? "client");
        return registered;
      }

      ModelRegistry.instance.register(model);
    }

    return model;
  }

  /**
   * Set the attributes of the model. This is called when the model instance is
   * created or updated from the database.
   */
  setAttributes(attributes: Attributes<C>) {
    this.rawAttributes = attributes;
    this.setId(isNil(attributes.id) ? undefined : String(attributes.id));
    this._attributes = new Map();
    this._data = new Map();
    this.dirtyAttributes = new Set();

    entries(attributes).forEach(([key, value]) => {
      if (typeof key !== "string") return;

      if (key === "id" || isNil(value) || value === undefined) {
        return;
      }

      if (this.fields[key]) {
        // Only set attributes for the keys we know in the schema
        this.attributes.set(key, value);
        // Dirty for new records
        if (!this.persisted) this.dirtyAttributes.add(key);
      } else if (key in this.references) {
        // Create models for references
        const ref = this.references[key] as ReferenceDescriptor<any>;
        const model = new (ref.model())(value);
        this._references[key].set(model);
      } else {
        // All other data is stored in the data map
        this.data.set(key, value);
      }
    });
  }

  /**
   * Update the attributes of the model without marking them as dirty.
   * Optionally skip attributes that are already dirty.
   */
  updateFromSubscription(attributes: Partial<Attributes<C>>, source: SubscriptionSource) {
    const pendingAttributes = this.pendingAttributes;
    this.rawAttributes = { ...this.rawAttributes, ...attributes };
    entries(attributes).forEach(([key, value]) => {
      if (typeof key !== "string") return;

      if (key === "id" || isNil(value) || value === undefined) {
        return;
      }

      // Skips dirty attributes
      if (this.dirtyAttributes.has(key)) {
        return;
      }

      // Skip attributes we just updated
      if (
        isNil(attributes["updated_at"]) ||
        isNil(this.rawAttributes["updated_at"]) ||
        dayjs(attributes["updated_at"]).isBefore(dayjs(this.rawAttributes["updated_at"]).add(1, "second"))
      ) {
        if (pendingAttributes.has(key)) {
          return;
        }
      }

      if (this.fields[key]) {
        this.attributes.set(key, value);

        // If the model has references loaded and the underlying reference key
        // has changed then reload the reference
        if (this._refKeys[key]) {
          const ref = this.readReference(this._refKeys[key]);
          if (ref.loaded && ref.id !== String(value)) {
            ref.reloadIfLoaded();
          }
        }
      } else {
        this.data.set(key, value);
      }

      this.dirtyAttributes.delete(key);
    });
  }

  /**
   * Does the model have any changes that need to be saved
   */
  get dirty() {
    return this.dirtyAttributes.size > 0;
  }

  /**
   * Is the model persisted in the database
   */
  get persisted() {
    return !!this.id;
  }

  defineFields() {
    const proto = Object.getPrototypeOf(this);

    Object.keys(this.fields).forEach((key) => {
      if (key in proto) return;

      Object.defineProperty(this, key, {
        get() {
          return this.readAttribute(key);
        },
        set(v) {
          this.writeAttribute(key, v);
        },
        configurable: true,
      });
    });
  }

  private _collections: Record<string, Collection<any>> = {};

  readCollection(key: string): Collection<any> {
    return this._collections[key];
  }

  replaceCollection(key: string, collection: Collection<any>) {
    this.readCollection(key).set(collection);
  }

  defineCollections() {
    Object.keys(this.collections).forEach((key) => {
      this._collections[key] = createCollection(this, key, this.collections[key]);

      Object.defineProperty(this, key, {
        get() {
          return this._collections[key];
        },
      });
      Object.defineProperty(this, `load${capitalize(key)}`, {
        get() {
          return (values: any[]) => {
            this._collections[key].set(values);
          };
        },
      });
    });
  }

  private _references: Record<string, Loadable<any>> = {};
  private _refKeys: Record<string, string> = {};

  readReference(key: string): Loadable<any> {
    return this._references[key];
  }

  replaceReference(key: string, reference: Loadable<any>) {
    if (reference.loaded) {
      this.readReference(key).set(reference.value);
    } else {
      this.readReference(key).unload();
    }
  }

  defineReferences() {
    Object.keys(this.references).forEach((key) => {
      const ref = this.references[key] as ReferenceDescriptor<any>;
      const model = ref.model();
      this._references[key] = loadableReference(this, model, ref.key as any);
      this._refKeys[ref.key] = key;

      Object.defineProperty(this, key, {
        get() {
          return this._references[key];
        },
        set(v) {
          this._references[key].set(v);
          this.writeAttribute(ref.key, v?.id);
        },
      });
    });
  }

  /**
   * Read the attribute from the model and convert it to the expected type. This doesn't need to be called directly because accessors use this under the hood:
   *
   * ```ts
   * const user = await User.query().find("1");
   * user.name === user.readAttribute("name");
   * ```
   */
  readAttribute<K extends keyof ModelFields<C>>(key: K & string): ModelFields<C>[K] | undefined {
    const adapter = (this.constructor as ModelConstructor<this>).adapter;
    const field = this.fields[key];
    let value: any = adapter.convertType(field || { type: "string" }, this.attributes.get(key));

    switch (field?.type) {
      case "json": {
        const jsonMarkers = ["{", "["];

        if (isNil(value)) {
          value = { ...this.fields[key].jsonType };
        } else if (typeof value === "string" && value.length > 0 && jsonMarkers.includes(value[0])) {
          try {
            return JSON.parse(value);
          } catch (e) {
            return undefined as any;
          }
        }

        return value;
      }
      case "datetime":
        if (!isNil(value)) {
          const dateValue = value as string;
          const [_date, time] = dateValue.split("T");
          let dateTime;

          if (!time) {
            dateTime = dayjs(`${dateValue}T00:00Z`).startOf("day").toDate();
          } else {
            if (time.includes("+") || time.includes("-") || time.includes("Z")) {
              dateTime = dayjs(dateValue).toDate();
            } else {
              dateTime = dayjs(`${dateValue}Z`).toDate();
            }
          }

          return dateTime as any;
        } else {
          return undefined;
        }
      case "string":
        if (!isNil(value)) {
          return String(value) as any;
        }
        break;
      case "boolean":
        if (typeof value === "string") {
          // @ts-ignore
          return value === "true";
        }
        break;
      case "number": {
        if (typeof value === "string") {
          return Number(value) as any;
        }
        break;
      }
      default:
      // noop
    }

    if (isNil(value)) return undefined;
    return value;
  }

  [inCallback] = false;

  /**
   * Write the attribute to the model and mark it as dirty if it has changed.
   * This doesn't need to be called directly because accessors use this under
   * the hood:
   *
   * ```ts
   * const user = await User.query().find("1");
   * user.name = "Noir";
   * // Same as:
   * user.writeAttribute("name", "Noir");
   * ```
   */
  writeAttribute<K extends keyof ModelFields<C>>(key: K & string, value: ModelFields<C>[K]): void {
    const oldValue = this.attributes.get(key);

    if (this.fields[key].type === "datetime" && !isNil(value)) {
      if (!(value instanceof Date)) {
        throw new Error(`Tried assiging a non-date to date field ${String(key)}`);
      }

      this.attributes.set(key, (value as Date).toISOString());
    } else if (isNil(value)) {
      this.attributes.set(key, null);
    } else {
      this.attributes.set(key, value);
    }

    if (this.attributes.get(key) !== oldValue || this.fields[key].type === "json") {
      this.dirtyAttributes.add(key);

      if (this._refKeys[key]) {
        const ref = this._references[this._refKeys[key]];
        if (ref.id !== value) ref.reloadIfLoaded();
      }
    }

    this.callWriteCallbacks(key);
  }

  private callWriteCallbacks(key: string) {
    if (this[inCallback]) return;
    const staticWriteCallbacks = Object.getPrototypeOf(this)[writeCallbacks] as
      | Map<string, Array<() => void>>
      | undefined;
    if (!staticWriteCallbacks) return;
    const callbacks = staticWriteCallbacks?.get(key);
    if (!callbacks) return;

    this[inCallback] = true;
    for (const callback of callbacks) {
      callback.call(this);
    }
    this[inCallback] = false;
  }

  /**
   * Get a list of changes that have been made to the model since the last save
   */
  changes() {
    const changes: Partial<ModelFields<C>> = {};
    this.dirtyAttributes.forEach((key) => {
      changes[key] = this.attributes.get(key as string);
    });
    return changes;
  }

  /**
   * Mark the model as clean, meaning no changes have been made since the last
   * save. Any changes won't be persisted when save is called:
   *
   * ```ts
   * const user = await User.query().find("1");
   * user.name // "Noir"
   * user.name = "Moon";
   * user.dirty() // true
   * user.markClean();
   * await user.save();
   * const user2 = await User.query().find("1");
   * user2.name // "Noir"
   * ```
   */
  markClean() {
    this.dirtyAttributes.clear();
  }

  /**
   * Persist the changes to the database. This only works if the model is
   * persisted. Use `save` to create or update.
   * @see save
   */
  async update(force?: boolean) {
    if (!this.persisted) throw new Error("Cannot update without an id");
    if (force) {
      this.debug("forcing update", this.tableName, this.id);
    } else if (this.dirty) {
      this.debug("updating because dirty", this.tableName, this.id, [...this.dirtyAttributes]);
    } else {
      this.debug("skipping update, no changes");
    }
    if (!force && !this.dirty) return;

    if (this.fields["updated_at"]) {
      // @ts-expect-error cannot know updated_at details in superclass
      this.writeAttribute("updated_at", new Date());
    }

    const attributes = Object.fromEntries(this.attributes) as Partial<ModelFields<C>>;
    const updateQuery = new UpdateQuery(this.constructor as ModelConstructor<this>, {
      adapter: Model.adapter,
    }).eq("id", this.id);

    this._pendingAttributeChanges.push(new Set(this.dirtyAttributes));
    this.markClean();
    await requestQueue.add(async () => {
      await updateQuery.set(attributes);
      setTimeout(() => {
        this._pendingAttributeChanges.shift();
      }, Model.UPDATE_DELAY);
    });

    SubscriptionManager.instance.emit(this.tableName, "UPDATE", {
      id: this.id,
      ...attributes,
    });
  }

  /**
   * Persist the model to the database. This only works if the model is not
   * persisted. Use `save` to create or update.
   * @see save
   */
  async create() {
    if (this.persisted) throw new Error("Already created");

    if (this.fields["updated_at"]) {
      // @ts-expect-error cannot know updated_at details in superclass
      this.writeAttribute("updated_at", new Date());
    }

    // Attributes we're sending
    const attributes = Object.fromEntries(this.attributes) as Partial<ModelFields<C>>;
    // We want to do a select on the attributes we're not sending and the id to
    // make sure we get defaults
    const undefinedFields = Object.keys(this.fields).filter((name) => attributes[name] === undefined);
    undefinedFields.push("id");

    const insertQuery = new InsertQuery(this.constructor as ModelConstructor<this>, {
      adapter: Model.adapter,
    }).select(...undefinedFields);

    const insertAttributes = await requestQueue.add(async () => await insertQuery.insert(attributes));
    if (!insertAttributes) throw new Error("Failed to insert model");

    // Rebuild the old and new attributes
    const newAttributes = { ...attributes, ...insertAttributes };
    this.setAttributes(newAttributes);
    this.markClean();
    ModelRegistry.instance.register(this as any);
    SubscriptionManager.instance.emit(this.tableName, "INSERT", {
      ...newAttributes,
    });
  }

  /**
   * Delete the model from the database.
   */
  async destroy() {
    if (!this.persisted) throw new Error("Cannot destroy without an id");

    const updateQuery = new UpdateQuery(this.constructor as ModelConstructor<this>, {
      adapter: Model.adapter,
    }).eq("id", this.id);

    await requestQueue.add(async () => await updateQuery.delete());

    ModelRegistry.instance.unregister(this as any);
    SubscriptionManager.instance.emit(
      this.tableName,
      "DELETE",
      {},
      {
        id: this.id,
      },
    );
  }

  // Prevent multiple saves per model from happening at the same time
  private saveQueue: PQueue = new PQueue({ concurrency: 1 });

  /**
   * Persist the model to the database.
   */
  async save() {
    if (this.persisted) {
      return raw(this.saveQueue).add(() => this.update());
    } else {
      return raw(this.saveQueue).add(() => this.create());
    }
  }

  /**
   * Reload the model from the database. Any unsaved changes are discarded.
   */
  async reload() {
    if (!this.persisted) throw new Error("Cannot reload without an id");
    const reloadedModel = await (this.constructor as ModelConstructor<any>)
      .query()
      .find(this.id!, { noCache: true });
    this.setAttributes(reloadedModel.rawAttributes);
  }

  async subscribe(callback?: (model: this) => void) {
    if (!this.persisted) throw new Error("Cannot subscribe an unsaved model");
    return SubscriptionManager.instance.add(this.tableName, { id: this.id! }, () => {
      this.reload().then(() => {
        if (callback) callback(this);
      });
    });
  }

  copyCollectionsTo(other: this) {
    Object.entries(this.collections).forEach(([name, _collection]) => {
      if (this.readCollection(name).loaded && !other.readCollection(name).loaded) {
        other.replaceCollection(name, this.readCollection(name));
      }
    });
  }

  copyReferencesTo(other: this) {
    Object.entries(this.references).forEach(([name, ref]) => {
      if (this.readReference(name).loaded && !other.readReference(name).loaded) {
        if (this.readAttribute(ref.key) === other.readAttribute(ref.key)) {
          other.replaceReference(name, this.readReference(name));
        }
      }
    });
  }
}

/**
 * A class decorator that takes the model class and adds the given model table and config
 */
export function model<T extends string>(tableName: T, config: AnyModelConfig) {
  return function <C extends { new (...args: any[]): Model<any> }>(
    constructor: C,
    _context?: ClassDecoratorContext,
  ): any {
    return class extends constructor {
      static tableName: T = tableName;
      static config = config;
    };
  };
}

/**
 * This is used to define a callback that should be called when a model attribute is set:
 *
 * @usage
 * class SomeModel extends Model {
 *  @onWrite("some_attribute")
 *  checkSomeAttribute(key: string) {
 *    // In this ccase key will always be "some_attribute"
 *  }
 * }
 *
 * @param key
 * @param rest
 * @returns
 */
export function onWrite(key: string, ...rest: string[]) {
  return function (target: any, _propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
    const targetPrototype = target.constructor.prototype;

    if (!targetPrototype[writeCallbacks]) {
      targetPrototype[writeCallbacks] = new Map();
    }
    const callbacks = targetPrototype[writeCallbacks] as Map<string, Array<() => void>>;
    const keys = [key, ...rest];
    const originalMethod = descriptor.value;

    for (const key of keys) {
      if (!callbacks.has(key)) {
        callbacks.set(key, []);
      }
      callbacks.get(key)?.push(function (this: any) {
        return originalMethod.call(this, key);
      });
    }

    return descriptor;
  };
}
