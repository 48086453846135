import { Button, DrawerCard, DrawerField, DrawerTitle } from "@mixitone/components";
import ClearIcon from "@mixitone/components/icons/clear.svg";
import UserIcon from "@mixitone/components/icons/user.svg";
import { Player } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import { RoutedDrawer } from "components/common/RoutedDrawer";
import { GroupDropdown } from "components/Drawers/NightPlayer/GroupDropdown";
import { PlayerDrawerController } from "components/Drawers/NightPlayer/PlayerDrawerController";
import PlayerDropdown from "components/Drawers/NightPlayer/PlayerDropdown";
import ClubPlayerForm from "components/Forms/ClubPlayerForm";
import PlayerDot from "components/Pages/Night/PlayerDot";
import { injectModal } from "lib/injectParams";
import { useCallback } from "react";

interface Props {
  token: string;
  clubId: string;
  id: string;
}

const PlayerDrawerContent = ApplicationView(() => {
  const controller = PlayerDrawerController.use();
  const { player, clubPlayer, clubPlayers, setGames, loading, toggling } = controller.state;
  const { group, pairedClubPlayer } = controller;

  const handlePlayerClick = useCallback(
    (player: Player) => () => {
      controller.actionSwitchDrawer(player.club_player_id!);
    },
    [],
  );

  if (loading) {
    return (
      <div className="flex animate-pulse flex-col gap-4">
        <DrawerCard>
          <DrawerField label="Name">&nbsp;</DrawerField>
          <DrawerField label="Group">&nbsp;</DrawerField>
          <hr />
          <DrawerField label="Pairing">&nbsp;</DrawerField>
          <hr />
          <DrawerField label="Notes">
            <div className="max-h-[150px] w-full overflow-y-auto">
              <div className="w-full rounded bg-gray-50 p-2 text-gray-50">&nbsp;</div>
            </div>
          </DrawerField>
        </DrawerCard>
        <DrawerCard>
          <DrawerField label="Games played">&nbsp;</DrawerField>
          <hr />
          <DrawerField label="Sat out">&nbsp;</DrawerField>
        </DrawerCard>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <DrawerCard>
        <DrawerField label="Name">
          <div className="flex w-full items-center justify-between gap-2">
            <span>{clubPlayer.name}</span>
            <Button kind="action" onClick={() => controller.actionEditPlayer(true)}>
              Edit
            </Button>
          </div>
        </DrawerField>
        <hr />
        <DrawerField label="Group">
          <GroupDropdown value={group!} />
        </DrawerField>
        <hr />
        <DrawerField label="Notes">
          <div className="max-h-[150px] w-full overflow-y-auto">
            <div className="w-full rounded bg-gray-50 p-2">{clubPlayer?.notes[0]?.note}</div>
          </div>
        </DrawerField>
      </DrawerCard>

      {!player && (
        <DrawerCard>
          <DrawerField label="Session">
            <Button kind="accent" onClick={() => controller.actionJoin()} spinner={toggling}>
              Join session
            </Button>
          </DrawerField>
        </DrawerCard>
      )}

      {player && (
        <>
          <DrawerCard>
            <DrawerField label="Session">
              <Button kind="danger" onClick={() => controller.actionRetire()} spinner={toggling}>
                Retire
              </Button>
            </DrawerField>
            <DrawerField label="Pairing">
              <PlayerDropdown
                clubPlayers={clubPlayers}
                value={pairedClubPlayer || null}
                onChange={(player) => controller.actionSetPairedPlayer(player)}
                canRegisterFromSearch={false}
              />
              <Button kind="plain" onClick={() => controller.actionSetPairedPlayer(null)}>
                <ClearIcon className="w-[20px] fill-gray-500" />
              </Button>
            </DrawerField>
            <hr />
            <DrawerField label="Games played">{player.timesPlayed}</DrawerField>
            <hr />
            <DrawerField label="Sat out">{player.timesOut}</DrawerField>
          </DrawerCard>

          <DrawerCard>
            <div className="flex flex-col gap-2">
              <label className="w-1/3 text-gray-600">Games</label>
              <table>
                <tbody>
                  {setGames.map(({ gameSet, players }, index) => {
                    return (
                      <tr key={`game-set-game-${index}`} className="border-b border-b-gray-200">
                        <td className="w-[60px] py-2 align-top">Set {gameSet.index! + 1}</td>
                        <td className="py-2 text-gray-500">
                          {players[0] && (
                            <PlayerDot onClick={handlePlayerClick(players[0])} inline player={players[0]} />
                          )}
                        </td>
                        <td className="py-2 text-gray-500">
                          {players[1] && (
                            <PlayerDot onClick={handlePlayerClick(players[1])} inline player={players[1]} />
                          )}
                        </td>
                        <td className="py-2 text-gray-500">
                          {players[2] && (
                            <PlayerDot onClick={handlePlayerClick(players[2])} inline player={players[2]} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </DrawerCard>
        </>
      )}
    </div>
  );
});

const NightPlayer = (props: Props) => {
  const controller = PlayerDrawerController.use();
  const { editing, loading, clubPlayer } = controller.state;

  return (
    <RoutedDrawer spinner={loading}>
      <DrawerTitle icon={<UserIcon className="mr-2 w-[36px] stroke-gray-500" />}>Player profile</DrawerTitle>
      {!editing && <PlayerDrawerContent />}
      {editing && (
        <DrawerCard>
          <ClubPlayerForm
            canDelete={false}
            canCancel={true}
            onSubmit={async () => controller.actionEditPlayer(false)}
            attributes={{ club_id: props.clubId }}
            id={clubPlayer.id}
          ></ClubPlayerForm>
        </DrawerCard>
      )}
    </RoutedDrawer>
  );
};

export default injectModal(PlayerDrawerController.scope(ApplicationView(NightPlayer)));
