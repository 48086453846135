import { Button, LoadingModal } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import { UserPermissionsController } from "components/modals/UserPermissions/UserPermissionsController";
import UserPermissionsTable from "components/modals/UserPermissions/UserPermissionsTable";
import { injectModal } from "lib/injectParams";
import { useCallback } from "react";

const UserPermissions: React.FC = () => {
  const controller = UserPermissionsController.use();
  const { accountUser, clubs, loading, saving, indexedPermissions } = controller.state;

  const handleSubmit = useCallback(() => {
    controller.actionSubmit();
  }, []);

  if (loading) return <LoadingModal title="Permissions" />;

  return (
    <AppModal title={`Permissions for ${accountUser.name}`}>
      {accountUser.isAdmin() && (
        <div className="mb-4 text-sm text-gray-500">
          Account admin has full access to all clubs and cannot have their permissions changed.
        </div>
      )}

      <UserPermissionsTable accountUser={accountUser} clubs={clubs} permissionsByClub={indexedPermissions} />

      <div className="mt-4 flex items-center justify-end gap-2">
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={accountUser.isAdmin() || saving}
          spinner={saving}
        >
          Save
        </Button>
      </div>
    </AppModal>
  );
};

export default injectModal(UserPermissionsController.scope(ApplicationView(UserPermissions)));
