import {
  Autocomplete,
  Button,
  LabelledField,
  Select,
  Spinner,
  bindControllerState,
} from "@mixitone/components";

import { Account, ClubPlayer, FillRule, Group } from "@mixitone/models";
import Club from "@mixitone/models/Club";
import { ApplicationView } from "@mixitone/mvc";
import { isPresent } from "@mixitone/util";
import { GroupMultiSelect, GroupSelect } from "components/GroupSelect";
import SearchIcon from "components/icons/SearchIcon";
import { ChangeEvent, useCallback } from "react";
import Form from "./Form";
import { FormController } from "./FormController";

class FillRuleFormController extends FormController<
  FillRule,
  { club_id?: string },
  {
    club: Club;
    subjectType: "" | "Player" | "Group";
    clubPlayers: ClubPlayer[];
    playerSubject?: ClubPlayer;
    groupSubject?: Group;
    selectedGroupIds: Set<string>;
    selectedGroups: Group[];
    valid?: boolean;
  },
  {}
> {
  override findModel(id: string): Promise<FillRule> {
    return FillRule.query().find(id);
  }
  override buildRecord(): FillRule {
    return new FillRule({
      account_id: Account.current?.id,
      club_id: this.state.attributes.club_id,
      rule_type: "never",
    });
  }
  override saveRecord(record: FillRule): Promise<void> {
    record.club_id = this.state.attributes.club_id;

    if (this.state.subjectType === "Player") {
      record.club_player_id = this.state.playerSubject?.id;
      record.group_id = undefined;
    } else {
      record.group_id = this.state.groupSubject?.id;
      record.club_player_id = undefined;
    }

    record.group_ids = Array.from(this.state.selectedGroupIds);

    return record.save();
  }
  override async onLoad() {
    this.state.subjectType = this.state.record.subjectType ?? "";
    this.state.club = await Club.query().find(this.state.attributes.club_id!);
    await this.state.club.groups.reload();
    await this.state.club.club_players.reload();
    this.state.clubPlayers = this.state.club.club_players;

    if (this.state.record.club_player_id) {
      this.state.playerSubject = this.state.club.club_players.findById(this.state.record.club_player_id);
    }
    if (this.state.record.group_id) {
      this.state.groupSubject = this.state.club.groups.findById(this.state.record.group_id);
    }
    this.state.selectedGroupIds = new Set(this.state.record.group_ids || []);

    this.observe(() => {
      this.state.selectedGroups = this.state.club.groups.filter((group) =>
        this.state.selectedGroupIds?.has(group.id!),
      );
    });

    this.observe(() => {
      this.state.valid =
        ((this.state.subjectType === "Player" && this.state.playerSubject) ||
          (this.state.subjectType === "Group" && this.state.groupSubject)) &&
        isPresent(this.state.record.rule_type);
    });
  }

  get group() {
    return (
      this.state.club.groups.find((group) => group.id === this.state.record.group_id) ||
      this.state.club.groups[0]
    );
  }

  override get canDelete(): boolean {
    return super.canDelete;
  }

  actionSetSubjectType(subjectType: "" | "Player" | "Group") {
    this.state.subjectType = subjectType;
  }
  actionSetPlayerSubject(player: ClubPlayer) {
    this.state.playerSubject = player;
  }
  actionSetGroupSubject(group: Group | undefined) {
    this.state.groupSubject = group;
  }
  actionSelectGroup(group: Group) {
    this.state.selectedGroupIds.add(group.id!);
  }
  actionUnselectGroup(group: Group) {
    this.state.selectedGroupIds.delete(group.id!);
  }
}

const FillRuleForm: React.FC = () => {
  const controller = FillRuleFormController.use();
  const {
    saving,
    loading,
    club,
    clubPlayers,
    errors,
    subjectType,
    playerSubject,
    groupSubject,
    selectedGroups,
    valid,
  } = controller.state;
  const handleChangeSubjectType = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    controller.actionSetSubjectType(e.target.value as "Player" | "Group");
  }, []);
  const handleChangeSubject = useCallback((e: ChangeEvent) => {}, []);

  if (loading) return <Spinner size={64} />;

  const { canDelete } = controller;
  const { bind } = bindControllerState(controller, "record");

  return (
    <Form name="rule">
      <fieldset disabled={saving}>
        <LabelledField label="Subject" htmlFor="subjectType">
          <Select name="subjectType" onChange={handleChangeSubjectType} value={subjectType}>
            <option value="">Select a rule subject</option>
            <option value="Player">Player</option>
            <option value="Group">Group</option>
          </Select>
          {subjectType === "Player" && (
            <Autocomplete
              className="w-full flex-grow bg-transparent py-2 pl-7 text-sm focus:outline-none"
              suggestions={clubPlayers}
              onSelect={(player: ClubPlayer) => {
                controller.actionSetPlayerSubject(player);
              }}
              renderSuggestion={(player) => player.name!}
              prefix={<SearchIcon className="w-[14px] stroke-gray-600" />}
              data-1p-ignore
              autoFocus
              error={errors?.name}
              onChange={handleChangeSubject}
              value={playerSubject?.name}
            />
          )}
          {subjectType === "Group" && (
            <GroupSelect
              name="subject-group"
              clubGroups={club.groups}
              onSelectGroup={(group) => controller.actionSetGroupSubject(group)}
              value={groupSubject}
            />
          )}
        </LabelledField>
        {subjectType === "" && <div className="mb-4 text-sm text-gray-600">Please select a subject type</div>}
        <LabelledField label="Rule" htmlFor="rule_type">
          <Select {...bind("rule_type")}>
            <option value="never">Never</option>
            <option value="only">Only</option>
            <option value="prefers">Prefers</option>
          </Select>
        </LabelledField>
        <LabelledField label="to play with" htmlFor="groups">
          <GroupMultiSelect
            name="groups"
            clubGroups={club.groups}
            onSelectGroup={(group) => controller.actionSelectGroup(group)}
            onUnselectGroup={(group) => controller.actionUnselectGroup(group)}
            value={selectedGroups}
          />
        </LabelledField>
      </fieldset>

      <div className="flex items-center justify-end gap-2">
        {canDelete && (
          <Button type="button" kind="danger" disabled={saving} onClick={() => controller.actionDelete()}>
            Delete
          </Button>
        )}
        <Button type="submit" disabled={!valid || saving} spinner={saving}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default FillRuleFormController.scope(ApplicationView(FillRuleForm));
