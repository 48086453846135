import { LocalStorageState } from "@mixitone/components";
import { AppController } from "controllers/AppController";
import MixitoneController from "controllers/MixitoneController";
import Club from "@mixitone/models/Club";
import Night from "@mixitone/models/Night";
import { batch } from "@mixitone/mvc";
import RouteController from "controllers/RouteController";

interface State {
  night: Night;
  loading: boolean;
  club: Club;
  updating: boolean;
  courts: number;
  player_fee?: number;
  game_set_time?: number;
  warm_up_time?: number;
  defaultTab: string;
}

interface Props {
  token: string;
}

export class SettingsController extends MixitoneController<State, Props> {
  static override initialState: Partial<State> = {
    loading: true,
    updating: false,
    courts: 5,
    player_fee: undefined,
    game_set_time: 15,
    warm_up_time: 10,
    defaultTab: "Session",
  };

  defaultTabState?: LocalStorageState<string>;

  override async initialize({ token }: Props) {
    const tab = this.get(RouteController).state.location.state?.tab;

    this.defaultTabState = new LocalStorageState<string>(`SettingsTab-${token}`, undefined, {
      session: true,
    });

    if (tab) {
      this.defaultTabState.set(tab);
    }

    this.state.defaultTab = this.defaultTabState.get();
    this.state.night = await Night.query().eq("id", token).waitFirst();
    this.state.club = await Club.query().eq("id", this.state.night.club_id).waitFirst();
    this.state.courts = this.night.courts || 5;
    this.state.game_set_time = this.night.game_set_time;
    this.state.warm_up_time = this.night.warm_up_time;
    this.state.player_fee = this.night.player_fee;

    // Basics loaded
    this.state.loading = false;
  }

  get night() {
    return this.state.night!;
  }

  async actionSubmit() {
    this.state.updating = true;

    await batch(async () => {
      await this.night.updateCourts(this.state.courts);
      this.night.player_fee = this.state.player_fee;
      this.night.game_set_time = this.state.game_set_time;
      this.night.warm_up_time = this.state.warm_up_time;
      await this.night.save();

      await this.state.club!.groups.save();
      await this.state.club!.fill_rules.reload();
    });

    this.state.updating = false;
    this.actionCloseModal();
  }

  async actionSetDefaultTab(tab: string) {
    this.defaultTabState?.set(tab);
  }

  get canCancel() {
    return this.night.game_sets.every((gameSet) => gameSet.duration < 1);
  }

  async actionCancel() {
    if (!this.canCancel) return;

    const yes = confirm("Are you sure you want to cancel this session?");

    if (yes) {
      await this.night.destroy();
      await this.get(AppController).actionReturnOrHome();
    }
  }
}
