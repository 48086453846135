import { ApplicationView } from "@mixitone/mvc";
import AppModal from "components/common/AppModal";
import FillRuleForm from "components/Forms/FillRuleForm";
import { injectModal } from "lib/injectParams";

interface Props {
  id: string;
  clubId: string;
}

const EditRule: React.FC<Props> = ({ clubId, id }) => {
  return (
    <AppModal title="Edit rule">
      <FillRuleForm attributes={{ club_id: clubId }} id={id} />
    </AppModal>
  );
};

export default injectModal(ApplicationView(EditRule));
