import { Button, LoadingModal } from "@mixitone/components";
import { Club } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import { List } from "@mixitone/oom";
import { isPresent } from "@mixitone/util";
import AppModal from "components/common/AppModal";
import MixitoneController from "controllers/MixitoneController";
import { injectModal } from "lib/injectParams";

interface State {
  loading: boolean;
  restoring?: string;
  clubs: List<Club>;
}

interface Props {}

class ArchivedClubsController extends MixitoneController<State, Props> {
  get initialState(): State {
    return {
      loading: true,
      clubs: new List(),
    };
  }

  async initialize(props: Props) {
    const clubs = await Club.query()
      .eq("is_deleted", true)
      .orderBy("updated_at", "desc")
      .limit(10)
      .preloadCounts("club_players")
      .all();
    this.state.clubs = new List(...clubs);
    this.state.loading = false;
  }

  async actionRestore(club: Club) {
    this.state.restoring = club.id;
    club.is_deleted = false;
    await club.save();
    this.actionForward("/clubs/:clubId", { params: { clubId: club.id! } });
  }
}

const ArchivedClubs: React.FC = () => {
  const controller = ArchivedClubsController.use();
  const { loading, clubs } = controller.state;

  if (loading || clubs.length === 0) return <LoadingModal title="Archived clubs" />;

  return (
    <AppModal title="Archived clubs">
      <ul className="flex flex-col gap-5">
        {clubs.map((club) => (
          <li key={club.domKey()} className="flex w-full items-center justify-between">
            <div className="flex flex-col">
              <span>{club.name}</span>
              <span className="text-sm">({club.club_players.count()} players)</span>
            </div>
            <Button
              onClick={() => controller.actionRestore(club)}
              spinner={controller.state.restoring === club.id}
              disabled={isPresent(controller.state.restoring)}
            >
              Restore
            </Button>
          </li>
        ))}
      </ul>
    </AppModal>
  );
};

export default injectModal(ArchivedClubsController.scope(ApplicationView(ArchivedClubs)));
