/**
 * Browser-related utilities
 */

import { keys } from "./object";

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

export function isBrowser() {
  return typeof window !== "undefined";
}

export function getCurrentBreakpoint(): keyof typeof breakpoints {
  if (!isBrowser()) return "md";
  const width = window.innerWidth;
  return keys(breakpoints).find((key: keyof typeof breakpoints) => width < breakpoints[key]) || "xl";
}

export function isGreaterThanBreakpoint(breakpoint: keyof typeof breakpoints) {
  return breakpoints[getCurrentBreakpoint()] > breakpoints[breakpoint];
}

export function getStackTrace() {
  const error = new Error();
  const trace = error?.stack?.split("\n").slice(3);
  return trace || [];
}

export function wwwUrl(path: string) {
  return `${import.meta.env.VITE_WWW}/${path}`;
}
