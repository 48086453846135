import ApplicationController from "@mixitone/mvc";
import { useModals, useNavigate } from "router";
import MessagesController, { MessageType } from "./MessagesController";
import RouteController from "./RouteController";

type NavigateType = ReturnType<typeof useNavigate>;
type ModalsType = ReturnType<typeof useModals>;

export default class MixitoneController<State extends object, Props = {}> extends ApplicationController<
  State,
  Props
> {
  /**
   * @example
   * controller.actionOpenModal("modalName", { state: { foo: "bar" } });
   */
  public actionOpenModal: ModalsType["open"] = (...args) => {
    this.get(RouteController).actionOpenModal(...args);
  };

  public actionCloseModal: ModalsType["close"] = (...args) => {
    this.get(RouteController).actionCloseModal(...args);
  };

  // @ts-ignore
  public actionForward: NavigateType = (...args) => {
    // @ts-ignore
    this.get(RouteController).actionForward(...args);
  };

  async actionBack() {
    this.debug("Going back");
    return this.get(RouteController).actionBack();
  }

  actionAddMessage(body: React.ReactNode, type: MessageType) {
    return MessagesController.addMessage(body, type);
  }
}
